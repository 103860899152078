import { Calendar } from "./style";
import InputLabel from "../../../InputLabel/index";
import { format } from "../../../../services/DateFormatter";
import { useState } from "react";
import Texts from "../../../../assets/json/texts.json";
import { useEffect } from "react";

export default function TipoSemanal({
  repetirACada,
  setRepetirACada,
  dataReserva,
  DataEncerramento,
  exibirTexto,
  setDataEncerramento,
  prazoMax,
  setDiasSelecionados,
  diasSelecionados,
}) {
  // eslint-disable-next-line no-unused-vars
  const [DiaFixo, setDiaFixo] = useState(new Date(dataReserva));

  useEffect(() => {
    setDiasSelecionados([Texts?.STATIC_OPC_DIAS_SEMANA[DiaFixo.getDay()]]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataReserva, DiaFixo]);

  const printDias = () => {
    let texto = "";
    if (diasSelecionados !== "") {
      diasSelecionados.map((e) => (texto += ` ${e?.slice(0, 3)},`));
      return texto;
    } else {
      texto = "nao funcionou";
      setDiasSelecionados([Texts?.STATIC_OPC_DIAS_SEMANA[DiaFixo.getDay()]]);
      return texto;
    }
  };

  const adicionarDia = (dia) => {
    setDiasSelecionados((prevDiasSelecionados) => {
       if (prevDiasSelecionados.includes(dia)) {
        return prevDiasSelecionados.filter((d) => d !== dia);
      } else {
        return [...prevDiasSelecionados, dia];
      }
    });
  };

  let textoSemanal =
    Number(repetirACada) === 1
      ? `Ocorre a cada${printDias()} a partir de ${format({
          DataInicial: dataReserva,
          Formato: "DD/MM/YYYY",
        })} até ${
          format({ DataInicial: DataEncerramento, Formato: "DD/MM/YYYY" }) || "?"
        }`
      : `Ocorre a cada ${repetirACada} semana(s) no${printDias()} a partir de ${format(
          { DataInicial: dataReserva, Formato: "DD/MM/YYYY" }
        )} até ${
          format({ DataInicial: DataEncerramento, Formato: "DD/MM/YYYY" }) || ""
        }`;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "50%",
          marginLeft: "4%",
          marginBottom: "2%",
        }}
      >
        <InputLabel
          type="number"
          label="Repetir a cada"
          onChange={(e) => setRepetirACada(e.target.value)}
          value={repetirACada}
          defaultValue={1}
        />
        <span style={{ alignContent: "center", marginLeft: "4%", color: "var(--second-auxiliary-color)" }}>
          Semana(s)
        </span>
      </div>

      <div className="listaSemana">
        <ul>
          {Texts?.STATIC_OPC_DIAS_SEMANA.map((dia) => (
            <li
              title={dia}
              key={dia}
              onClick={() => adicionarDia(dia)}
              style={{
                backgroundColor: diasSelecionados.includes(dia)
                  ? "black"
                  : "var(--light-silver)",
                color: diasSelecionados.includes(dia) ? "white" : "black",
              }}
            >
              {dia.slice(0, 1)}
            </li>
          ))}
        </ul>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "flex-start",
          gap: "5%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Calendar />
          <InputLabel
            value={format({ DataInicial: dataReserva, Formato: "YYYY-MM-DD" })}
            type="date"
            label="Iniciar"
          />
        </div>

        <div style={{ width: "30%" }}>
          <InputLabel
            value={
              format({ DataInicial: DataEncerramento, Formato: "YYYY-MM-DD" }) || DataEncerramento
            }
            type="date"
            label="Encerrar"
            onChange={(e) => setDataEncerramento(e.target.value)}
            max={prazoMax}
          />
        </div>
      </div>

      <div
        style={{
          display: exibirTexto ? "flex" : "none",
          flexDirection: "row",
          justifyContent: "flex-start",
          width: "100%",
          marginLeft: "10%",
          marginTop: "2%",
        }}
      >
        <p style={{ fontSize: "14px" , maxWidth:"80%", color: "var(--second-auxiliary-color)"}}>{textoSemanal}</p>
      </div>
    </>
  );
}
