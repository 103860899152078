import React, { useState, useEffect } from "react";
import Texts from "../../assets/json/texts.json";
import {
  Background,
  Container,
  Table,
  Header,
  Button,
  RemoveIcon,
  ExternalIcon,
  ScrollTable,
} from "./style";
import FileInput from "../../components/FileInput";
import api from "../../services/api";
import { Swal, Toast } from "../../services/SwalTemplates";
import { useLocation } from "react-router-dom";
import GoBack from "../../components/GoBack";
import { format } from "../../services/DateFormatter";
import { formatFilename } from "../../services/FilenameFormatter";
import { userId } from "../../services/TokenData";

export default function AgendamentosAnexos() {
  const [Dados, setDados] = useState([]);
  const [ShowFileInput, setShowFileInput] = useState(false);
  const [Anexo, setAnexo] = useState(null);
  const [RealodData, setRealodData] = useState(false);
  const Location = useLocation();
  const UserId = userId();

  function deletaAnexo(id, anexo) {
    Swal.fire({
      title: `Deseja excluir o anexo "${anexo}"?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--deep-carmine-pink)",
      cancelButtonColor: "gray",
      confirmButtonText: "DELETAR",
      cancelButtonText: "CANCELAR",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .delete(`/agendamentos_anexos/${id}`, {
            data: { id_usuario_acao: userId() },
          })
          .then((res) => {
            Toast.fire({
              title: `Anexo deletado.`,
              icon: "success",
            });
            setRealodData((rd) => !rd);
          });
      }
    });
  }

  useEffect(() => {
    api.get(`/agendamentos_anexos/${Location?.state?.Id}`).then((response) => {
      setDados(response.data.anexos_agendamento);
    });
  }, [Location?.state?.Id, RealodData]);

  return (
    <Background>
      <GoBack />
      <Container>
        <Header>
          <Button
            disabled={Location?.state?.IdAgendadoPor !== UserId ? true : false}
            onClick={() => setShowFileInput(true)}
          >
            Novo
          </Button>
          <table>
            <tbody>
              <tr>
                <th>Título: </th>
                <td>{Location?.state.Titulo}</td>
                <th>Dia: </th>
                <td>{Location?.state.Dia}</td>
              </tr>
              <tr>
                <th>Horário:</th>
                <td>
                  {`${Location?.state?.HoraInicial.slice(0, 5)} -
                      ${Location?.state?.HoraFinal.slice(0, 5)}`}
                </td>
                <th>Agendado por:</th>
                <td>{Location?.state?.AgendadoPor}</td>
              </tr>
            </tbody>
          </table>
        </Header>
        <ScrollTable>
          <Table>
            <thead>
              <tr>
                <th>Seq.</th>
                <th>Título</th>
                <th>Data</th>
                <th>Arquivo</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Dados?.map((d, i) => (
                <tr key={i}>
                  <td>{d?.seq_anexo}</td>
                  <td>{d?.titulo_anexo}</td>
                  <td>
                    {format({
                      DataInicial: d?.data_inclusao,
                      Formato: "DD/MM/YYYY",
                    })}
                  </td>
                  <td className="td-icon">
                    {formatFilename(d?.nome_arquivo)}
                    <ExternalIcon
                      onClick={() => {
                        window.open(
                          window.location.href.includes("localhost")
                            ? `${Texts.STATIC_URL_DEV}/${d?.url_anexo}`
                            : `${Texts.STATIC_URL_PROD}/${d?.url_anexo}`
                        );
                      }}
                    />
                  </td>
                  <td>
                    <RemoveIcon
                      disabled={
                        Location?.state?.IdAgendadoPor !== UserId ? true : false
                      }
                      onClick={() => deletaAnexo(d?.id, d?.titulo_anexo)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ScrollTable>
      </Container>
      <FileInput
        setShow={setShowFileInput}
        Show={ShowFileInput}
        Anexo={Anexo}
        setAnexo={setAnexo}
        IdAgendamento={Location?.state?.Id}
        Origin={"AnexoAgendamento"}
      />
    </Background>
  );
}
