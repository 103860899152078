import React, { useState } from "react";
import {
  Background,
  Form,
  CloseIcon,
  RemoveIcon,
  EditAppointment,
  AnexoIcon,
  ParticipantesIcon,
} from "./style";
import { format } from "../../../services/DateFormatter";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../../services/api";
import { TokenPerfil, userId } from "../../../services/TokenData";
import ModalEdit from "../EditAgendamento";

export default function ExibirAgendamento({ Show, setShow, Dados, IdLocal, Reload, setReload }) {
  const [ShowModalEditarAgendamento, setShowModalEditarAgendamento] =
    useState(false);
  const UserId = userId();

  const Navigate = useNavigate();
  async function Close() {
    setShow(false);
    setReload(!Reload);
  }

  function excluirAgendamento() {
    Swal.fire({
      title: `Deseja excluir este agendamento?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--deep-carmine-pink)",
      cancelButtonColor: "gray",
      confirmButtonText: "DELETAR",
      cancelButtonText: "CANCELAR",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .delete(`/agendamentos/${Dados.id}}`, {
            data: { id_usuario_acao: UserId },
          })
          .then((res) => {
            Swal.fire(`Agendamento excluído.`, "", "success");
            setReload(!Reload);
          });
      }
    });
  }

  return (
    <Background Show={Show}>
      <Form>
        <CloseIcon onClick={() => Close()} />
        <div
          style={{
            display: "flex",
            flexDirection:"row",
            justifyContent:"center",
            alignItems: "center",
            marginRight: "auto",
            gap: "15px",
            width:"100%"
          }}
        >
          <AnexoIcon
            title="Exibir anexos?"
            onClick={() =>
              Navigate(
                TokenPerfil() === "GESTOR"
                  ? `/gestor/anexos_reserva`
                  : `/operador/anexos_reserva`,
                {
                  state: {
                    Id: Dados?.id,
                    Titulo: Dados?.titulo || Dados?.observacao,
                    Dia: format({
                      DataInicial: Dados?.dia,
                      Formato: "DD/MM/YYYY",
                    }),
                    IdAgendadoPor: Dados?.id_usuario,
                    AgendadoPor: Dados?.nome || "",
                    HoraInicial: Dados?.hora_inicio || Dados?.hora_inicial,
                    HoraFinal: Dados?.hora_fim || Dados?.hora_final,
                  },
                }
              )
            }
          />
          <ParticipantesIcon
            title="Exibir participantes?"
            onClick={() =>
              Navigate(`/participantes_agendamento`, {
                state: {
                  Id: Dados?.id,
                  Titulo: Dados?.titulo || Dados?.observacao,
                  Dia: format({
                    DataInicial: Dados?.dia,
                    Formato: "DD/MM/YYYY",
                  }),
                  IdAgendadoPor: Dados?.id_usuario,
                  AgendadoPor: Dados?.nome || "",
                  HoraInicial: Dados?.hora_inicio || Dados?.hora_inicial,
                  HoraFinal: Dados?.hora_fim || Dados?.hora_final,
                  Observacao: Dados?.observacao,
                  IdLocal: Dados?.id_local,
                  IdUsuario: Dados?.id_usuario || "nao sei",
                },
              })
            }
          />
          <EditAppointment
            disabled={UserId !== Dados?.id_usuario ? true : false}
            title="Editar agendamento?"
            onClick={() => {
              setShowModalEditarAgendamento(true);
            }}
          />
          <RemoveIcon
            disabled={UserId !== Dados?.id_usuario ? true : false}
            title="Excluir agendamento?"
            onClick={() => {
              excluirAgendamento();
            }}
          />
        </div>
        <div className="infoSol">
          <p>
            <strong>Título: </strong> {Dados?.titulo || Dados?.descricao}
          </p>
          <p>
            <strong>Dia: </strong>{" "}
            {format({ DataInicial: Dados?.dia, Formato: "DD/MM/YYYY" })}
          </p>
          <p>
            <strong>Agendado por: </strong> {Dados?.nome || 'Não disponivel'}
          </p>
          <p>
            <strong>Agendado em: </strong>
            {format({ DataInicial: Dados?.agendadoEm, Formato: "DD/MM/YYYY" })}
          </p>
          <p>
            <strong>Horário inicial: </strong>
            {Dados?.hora_inicio || Dados?.hora_inicial}
          </p>
          <p>
            <strong>Horário final: </strong> {Dados?.hora_fim || Dados?.hora_final}
          </p>
          <p>
            <strong>observacao: </strong>
            {Dados?.observacao || "Não há"}
          </p>
        </div>
      </Form>
      <ModalEdit
        Show={ShowModalEditarAgendamento}
        setShow={setShowModalEditarAgendamento}
        Dados={Dados}
        IdLocal={IdLocal}
        Reload={Reload}
        setReload={setReload}
      />
    </Background>
  );
}
