import styled from "styled-components";
import { FaCalendarDay } from "react-icons/fa";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  padding: 50px 50px 20px;
  border-radius: 5px;
  background-color: var(--primary-bg-color);
  gap: 20px;

  .titulo,
  .horarios,
  .data,
  .participantes,
  .check-add {
    display: flex;
    gap: 10px;
    width: 100%;
    .horario-input {
      display: flex;
      width: 50%;
      gap: 10px;
    }
    .add {
      display: flex;
      width: 100%;
      gap: 10px;
    }
  }
  .repetir {
    display: flex;
    margin-right: auto;
    gap: 10px;
    width: 40%;
  }
  
  .check-add {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-size: 16px;
  }
  .check-repetir{
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-right:auto;
  }
  .check-repeticao{
    font-size: 5px;
    transform: scale(1.5);
    
  }
  label {
    padding-left: 10px;
  }
  .check-adicionar {
    font-size: 5px;
    transform: scale(1.5);
  }

  .selectRepeticao{
    width:50%;
    margin-right:auto;
    margin-left:25px;
  }

  @media screen and (max-width: 768px) {
    padding: 50px 20px 20px;
  }
`;

export const Calendar = styled(FaCalendarDay)`
  align-self: center;
  margin-right:15px;
  color: var(--second-auxiliary-color);
`;
