import styled from "styled-components";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { FaCaretDown } from "react-icons/fa6";
import { HiTrash, HiPencil } from "react-icons/hi";
import { TiArrowBack } from "react-icons/ti";

export const Background = styled.div`
  min-width: 99vw;
  width: 100%;
  min-height: calc(100vh - 60px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 50px;
  position: relative;

  @media (max-width: 1440px) {
    padding-top: 100px;
    padding-bottom: 0px;
}
`;

export const Container = styled.div`
  height: fit-content;
  min-height:200px;
  width: 97%;
  background-color: var(--secondary-bg-color);
  border-radius: 10px;
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  justify-content: start;
  box-sizing: border-box;

  .headerPag{
    display:flex;
    flex-direction:row;
    justify-content:center;
    height:fit-content;
    width:100%;
  }
  h1{
    font-size:40px;
    width:90%;
    text-align:center;
    margin-top:${(props) => props.EspacoSelecionado === 0 ? "1%" : "0%"};
    margin-bottom:${(props) => props.EspacoSelecionado === 0 ? "2%" : "0%"};
  }
  .quadroDeEspacos{
    width:${(props) => props.EspacoSelecionado === 0 ? "80%" : "100%"};
    display:grid;
    grid-template-columns:${(props) => props.EspacoSelecionado === 0 ? "repeat(4, 1fr)" : "1fr"};
    gap:1%;
    row-gap:10px;
    justify-items:center;
  }
  .listaDeEspacos{
    width:90%;
    border: 2px solid #083D77;
    background-color:#155AA9;
    color:white;
    border-radius:10px;
    height:60px;
    display:flex;
    flex-direction:row;
    justify-content:center;
      h2{
        text-align:center;
        font-size:20px;
        width:80%;
        align-content:center;
      }
  }
  .listaDeEspacos:hover{
    transform: scale(1.1);
    transition: transform 1s;
    cursor: pointer;
  }

  .espacoSelecionado{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-content:center;
    align-items:center;
    width:95%;
    border: 2px solid white;
    background-color:#155AA9;
    color:white;
    border-radius:5px;
    height:50px;
    text-align:center;
    margin-top:1%;
    h2{
      font-size:30px;
      width:95%;
    }
  }

  .moreInformation{
    width:93%;
    background-color:#155AA9;
    display:flex;
    flex-direction:row;
    justify-content:center;
    border-end-start-radius:5px;
    border-end-end-radius:5px;
    border-right: 2px solid #083D77;
    border-left: 2px solid #083D77;
    border-bottom: 2px solid #083D77;
  }
`;

export const ScrollTable = styled.div`
  overflow-x: auto;
  align-self: center;
  width: 99%;
  height: fit-content;
  display:${(props) => props.EspacoSelecionado === 0 ? "none" : "flex"};
  border-radius:5px;
  border: 2px solid white;
  margin:0.5% 0%;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  color: var(--secondary-font-color);
  background-color: #155AA9;
  border-radius: 5px;
  height:20px;
  color:white;
  td,
  th {
    padding: 10px;
  }

  tbody {
    tr:hover {
      background-color: var(--table-secondary-color);
    }
  }

  tr {
    border: 1px solid white;
  }
`;
export const MoreInformation = styled(FaEye)`
  cursor: pointer;
  justify-self: end;
  align-self: center; 
  font-size:25px;
  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`;
export const LessInformation = styled(FaEyeSlash)`
  cursor: pointer;
  justify-self: end;
  align-self: center; 
  font-size:30px;
  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`;
export const MenosInformacao = styled(FaCaretDown)`
  cursor: pointer;
  /* justify-self: end;
  align-self: center;  */
  width:5%;
  text-align:end;
  font-size:30px;
  height:100%;
  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`;

export const RemoveIcon = styled(HiTrash)`
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`;

export const EditIcon = styled(HiPencil)`
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`;

export const ShowIcon = styled(FaEye)`
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`;

export const BackIcon = styled(TiArrowBack)`
  font-size: 50px;
  cursor: pointer;
  color: var(--main-color);
  transition: transform 0.2s;
  align-self:center;
  justify-self:center;
  display:${(props) => props.EspacoSelecionado ? "flex" : "none"};
  /* margin-bottom:1%; */
  &:hover {
    transform: translateX(-5px);
  }
`;