import axios from "axios";
import Texts from "../assets/json/texts.json";

const api = axios.create({
  baseURL: window.location.href.includes("localhost") || window.location.href.includes("10.5.17.5")
    ? Texts.STATIC_URL_DEV
    : Texts.STATIC_URL_PROD,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
    }
    return Promise.reject(error);
  }
);

export default api;
