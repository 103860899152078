import styled from "styled-components";
import {
  MdKeyboardArrowRight,
  MdKeyboardArrowLeft,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  color: var(--secondary-font-color);
  background-color: var(--table-primary-color);
  border-radius: 5px;
  margin-top: 3px;

  caption {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    color: var(--secondary-font-color);
    background-color: var(--table-primary-color);
    border-radius: 5px;
    font-size: 25px;
    padding: 10px;
    margin-bottom: 2px;
  }

  thead {
    height: 25px;
    width: 100%;  
  }

  .headLine {
    border-bottom: 2px solid var(--secondary-bg-color);
    width: 100%;
  }

  .conteudoHeader {
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    justify-items: center;
    h4 {
      min-width: 15%;
    }
  }

  td,
  th {
    padding: 10px;
  }
  th {
    height: 30px;
  }
  tbody {
    td {
      border-right: 1px solid var(--secondary-bg-color);
    }

    .dados {
      text-align: center;
      
      span {
        font-weight: 600;
      }
    }
    .dados:hover {
      cursor: help;
      background-color: var(--gray);
      color: white;
    }

    .semDados {
      border: none;
    }

    /* .semDados:hover {
      background-color: var(--la-salle-green);
    } */

    .indisponivel {
      cursor: not-allowed;
      border: none;
    }
  }

  tr {
    border: 1px solid var(--table-border-color);
  }
`;

export const PreviousOne = styled(MdKeyboardArrowLeft)`
  font-size: 25px;
  font-weight: 800;
  cursor: pointer;
  margin-right: 15px;

  &:hover {
    background-color: var(--main-color);
  }
`;

export const NextOne = styled(MdKeyboardArrowRight)`
  font-size: 25px;
  font-weight: 800;
  cursor: pointer;
  margin-left: 15px;

  &:hover {
    background-color: var(--main-color);
  }
`;

export const PreviousDouble = styled(MdKeyboardDoubleArrowLeft)`
  font-size: 25px;
  font-weight: 800;
  cursor: pointer;
  margin-right: 5px;

  &:hover {
    background-color: var(--main-color);
  }
`;

export const NextDouble = styled(MdKeyboardDoubleArrowRight)`
  font-size: 25px;
  font-weight: 800;
  cursor: pointer;

  &:hover {
    background-color: var(--main-color);
  }
`;
