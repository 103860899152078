import styled from "styled-components";
import { FaGear } from "react-icons/fa6";
import { TiArrowBack } from "react-icons/ti";
import {
  MdKeyboardArrowRight,
  MdKeyboardArrowLeft,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";

export const Title = styled.h1`
  width: 60%;
  font-size: 30px;
  text-align: center;
  display:flex;
  flex-direction:row;
  justify-content:end;
  gap:50px;
  h1{
    font-size:40px;
    font-weight:600;
  }
`;

export const Background = styled.div`
  min-width: 99vw;
  width: 100%;
  min-height: calc(100vh - 60px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 50px;
  position: relative;

  @media (max-width: 1440px) {
    padding-top: 100px;
    padding-bottom: 0px;
  }
`;

export const Container = styled.div`
  height: fit-content;
  width: 97%;
  align-items: center;
  background-color: var(--secondary-bg-color);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;

  .calendarMonth {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-auto-rows: 150px;
    width: 100%;
    border-radius: 5px;
    div {
      border: 1px solid black;
    }
  }

  .buttons {
    width: fit-content;
    display: flex;
    flex-direction: row;

    height: fit-content;
    .tipoCalendario {
      display: flex;
      gap: 20px;
    }
  }

  .legendaReservas {
    width: fit-content;
    margin-right: auto;
    margin-left: 0.5%;
    margin-top: 1%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;

    span {
      color: var(--primary-font-color);
    }
  }

  @media (max-width: 1440px) {
    padding: 15px;
  }
`;
export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  color: var(--secondary-font-color);
  background-color: var(--table-primary-color);
  border-radius: 5px;
  margin-top: 10px;

  caption {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    color: var(--secondary-font-color);
    background-color: var(--table-primary-color);
    border-radius: 5px;
    font-size: 25px;
    padding: 10px;
    margin-bottom: 1px;
  }

  thead {
    height: 25px;
    width: 100%;  
  }

  .headLine {
    border-bottom: 2px solid var(--secondary-bg-color);
    width: 100%;
  }

  .conteudoHeader {
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50px;
    justify-items: center;
    h4 {
      min-width: 15%;
    }
  }

  td,
  th {
    padding: 10px;
  }
  th {
    height: 30px;
  }
  tbody {
    td {
      border-right: 1px solid var(--secondary-bg-color);
    }
  }
  tr {
    border: 1px solid var(--table-border-color);
  }
`;

export const ScrollTable = styled.div`
  overflow-x: auto;
  align-self: center;
  width:${(props) => props.TipoDeCalendario === "mensal" ? "90%" : "100%"};
  height: 100%;
  max-height: 60vh;
  scrollbar-width:${(props) => props.TipoDeCalendario === "mensal" ? "none" : ""};
  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    background: #155AA9;
  }
  ::-webkit-scrollbar-thumb {
    background-color: white;
    border: 1px solid black;
  }
`;

export const TypeCalendar = styled.button`
  border: none;
  align-self: start;
  height: fit-content;
  border-radius: 5px;
  padding: 5px 20px;
  background-color: blue;
  color: var(--button-text-color);
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  transition: transform 0.5s;
  transition: box-shadow 0.5s;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  }
`;

export const ConfigIcon = styled(FaGear)`
  cursor: pointer;
  justify-self: center;
  align-self: center; 
  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`;

export const BackIcon = styled(TiArrowBack)`
  font-size: 50px;
  cursor: pointer;
  color: var(--main-color);
  transition: transform 0.2s;
  align-self:center;
  justify-self:center;
  display:${(props) => props.TipoDeCalendario !== "mensal" ? "flex" : "none"};
  /* margin-bottom:1%; */
  &:hover {
    transform: translateX(-5px);
  }
`;

export const PreviousOne = styled(MdKeyboardArrowLeft)`
  font-size: 25px;
  font-weight: 800;
  cursor: pointer;
  margin-right: 15px;

  &:hover {
    background-color: var(--main-color);
  }
`;

export const NextOne = styled(MdKeyboardArrowRight)`
  font-size: 25px;
  font-weight: 800;
  cursor: pointer;
  margin-left: 15px;

  &:hover {
    background-color: var(--main-color);
  }
`;

export const PreviousDouble = styled(MdKeyboardDoubleArrowLeft)`
  font-size: 25px;
  font-weight: 800;
  cursor: pointer;
  margin-right: 5px;

  &:hover {
    background-color: var(--main-color);
  }
`;

export const NextDouble = styled(MdKeyboardDoubleArrowRight)`
  font-size: 25px;
  font-weight: 800;
  cursor: pointer;

  &:hover {
    background-color: var(--main-color);
  }
`;
