import {
  Background,
  Title,
  Button,
  Form,
  Clock,
  PersonAdd,
  Pencil,
  CloseIcon,
} from "./style";
import InputLabel from "../../../components/InputLabel";
import SelectLabel from "../../../components/SelectLabel";
import React, { useState, useEffect } from "react";
import { format } from "../../../services/DateFormatter";
import api from "../../../services/api";
import { userId } from "../../../services/TokenData";
import Texts from "../../../assets/json/texts.json";
import { Swal, Toast } from "../../../services/SwalTemplates";
import TipoDiario from "../../Calendar/TipoDeRepeticao/TipoDiario";
import TipoSemanal from "../../Calendar/TipoDeRepeticao/TipoSemanal";
import FileInput from "../../FileInput";
import Observação from "../Observacao_Agendar";

export default function Agendar({
  Show,
  setShow,
  horario,
  setDataReserva,
  dataReserva,
  idLocal,
  prazo,
}) {
  const [Titulo, setTitulo] = useState("");
  const [Data, setData] = useState(dataReserva);
  const [HoraInicio, setHoraInicio] = useState(horario);
  const [HoraFim, setHoraFim] = useState("");
  const [Destinatarios, setDestinatarios] = useState(null);
  const [IdDestinatarios, setIdDestinatarios] = useState([]);
  const diasDaSemana = [
    "Domingo",
    "Segunda-feira",
    "Terça-feira",
    "Quarta-feira",
    "Quinta-feira",
    "Sexta-feira",
    "Sábado",
  ];
  const [isChecked, setIsChecked] = useState(false);
  const [Repetir, setRepetir] = useState(false);
  const [TipoDeRepeticao, setTipodeRepeticao] = useState(
    Texts.STATIC_OPC_REPETIR[0]
  );
  
  const [DataEncerramento, setDataEncerramento] = useState(dataReserva);
  const [RepetirACada, setRepetirACada] = useState(1);
  const [DiasSelecionados, setDiasSelecionados] = useState([]);
  const [Selecionados, setSelecionados] = useState([]);
  const [DadosParticipantes, setDadosParticipantes] = useState([]);
  const [ShowFileInput, setShowFileInput] = useState(false);
  const [Anexo, setAnexo] = useState(null);
  const [ShowModalObservacao, setShowModalObservacao] = useState(false);
  const [Observacao, setObservacao] = useState("");
  const [Disabled, setDisabled] = useState(false);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };
  const handleOnChangeRepeat = () => {
    setRepetir(!Repetir);
    setTipodeRepeticao(Texts.STATIC_OPC_REPETIR[0]);

    setDataEncerramento("");
    setRepetirACada(1);
  };
  useEffect(() => {
    async function Request() {
      api.get(`/user/destinatarios/${userId()}`).then((response) => {
        setDestinatarios([
          "Ninguém",
          ...response.data.usuarios.map((r) => r.nome),
        ]);
        setDadosParticipantes([...response.data.usuarios]);
      });
    }
    Request();
    setHoraInicio(horario);
    setHoraFim(
      Texts?.STATIC_OPC_HORARIOS_FIM[
        Texts?.STATIC_OPC_HORARIOS_INICIO.indexOf(horario)
      ]
    );
    const diaFixo = new Date(dataReserva);
    setData(dataReserva);
    setDataEncerramento(dataReserva);
    setHoraInicio(horario);
    setTipodeRepeticao(Texts.STATIC_OPC_REPETIR[0]);
    setDiasSelecionados([Texts?.STATIC_OPC_DIAS_SEMANA[diaFixo.getDay()]]);
  }, [Show, horario, dataReserva]);

  function maximo() {
    const diaMax = new Date();

    diaMax.setDate(diaMax.getDate() + prazo);
    return diaMax;
  }

  async function HandleApiCall(data) {
    await api
      .post(`/agendamentos`, { data })
      .then((response) => {
        IdDestinatarios.forEach((destinatario) => {
          api
            .post(`/agendamentos_participantes`, {
              id_agendamento: response.data.id,
              id_usuario: destinatario,
            })
            .then((response) => {
              if (!!response.data.success) {
                api.post(`/user/mail_agendamento/${destinatario}`, { data });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        });

        if (!!Anexo) {
          const config = {
            headers: { "content-type": "multipart/form-data" },
          };
          Anexo.set("id_agendamento", response?.data?.id);

          api
            .post(`/agendamentos_anexos`, Anexo, config)
            .then((resAnexo) => {})
            .catch((e) => {
              Swal.fire({
                title: "Atenção!",
                text: e,
                icon: "error",
              });
            });
        }
      })
      .catch((e) => {
        console.log(e);
        Toast.fire({
          text: "Não é possível realizar dois agendamentos no mesmo horário!",
          icon: "error",
          timer: 2500,
        });
      });
  }

  async function HandleSubmit(e) {
    Swal.fire({
      title: "Agendando...",
      html: "Aguarde, por favor...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    setDisabled(true);
    e.preventDefault();
    const diaDaSemana = new Date(
      format({ DataInicial: Data, Formato: `YYYY-MM-DD` })
    );

    let horaFim = new Date(dataReserva);

    horaFim = new Date(horaFim).setHours(HoraFim.slice(0, 2));
    horaFim = new Date(horaFim).setMinutes(HoraFim.slice(3, 5));
    horaFim = new Date(horaFim).setSeconds(HoraFim.slice(6, 8));
    horaFim = new Date(horaFim).setMilliseconds(0);

    let horaInicio = new Date(dataReserva);

    horaInicio = new Date(horaInicio).setHours(HoraInicio.slice(0, 2));
    horaInicio = new Date(horaInicio).setMinutes(HoraInicio.slice(3, 5));
    horaInicio = new Date(horaInicio).setSeconds(HoraInicio.slice(6, 8));
    horaInicio = new Date(horaInicio).setMilliseconds(0);

    const data = {
      titulo: Titulo,
      dia: dataReserva,
      id_usuario: userId(),
      hora_inicio: HoraInicio.slice(0, 5),
      hora_fim: HoraFim.slice(0, 5),
      id_local: idLocal,
      observacao: Observacao || null,
      dia_semana: diasDaSemana[diaDaSemana.getDay() + 1] || "Dom",
    };

    let diafinal = new Date(DataEncerramento);
    diafinal.setDate(diafinal.getDate() + 1);

    if (new Date(horaFim) - new Date(horaInicio) > 1800000) {
      const data1 = {
        titulo: Titulo,
        dia: dataReserva,
        id_usuario: userId(),
        hora_inicio: HoraInicio.slice(0, 5),
        hora_fim: Texts.STATIC_OPC_HORARIOS_FIM[
          Texts.STATIC_OPC_HORARIOS_FIM.indexOf(`${HoraInicio}`) + 1
        ].slice(0, 5),
        id_local: idLocal,
        observacao: null,
        dia_semana: diasDaSemana[diaDaSemana.getDay() + 1] || "Dom",
      };

      let flag = 0;
      while (dataReserva.getTime() <= diafinal.getTime()) {
        const diasSelecionados = [];
        DiasSelecionados.map((e) => diasSelecionados.push(e.slice(0, 3)));

        if (TipoDeRepeticao === "Diariamente") {
          do {
            await HandleApiCall(data1);

            data1.hora_inicio = Texts.STATIC_OPC_HORARIOS_INICIO[
              Texts.STATIC_OPC_HORARIOS_INICIO.indexOf(
                `${data1.hora_inicio}:00`
              ) + 1
            ]?.slice(0, 5);
            data1.hora_fim = Texts.STATIC_OPC_HORARIOS_FIM[
              Texts.STATIC_OPC_HORARIOS_FIM.indexOf(`${data1.hora_fim}:00`) + 1
            ]?.slice(0, 5);
          } while (
            data1.hora_fim !==
              Texts.STATIC_OPC_HORARIOS_FIM[
                Texts.STATIC_OPC_HORARIOS_FIM.indexOf(`${data.hora_fim}:00`) + 1
              ]?.slice(0, 5) &&
            data1.hora_fim !== undefined
          );
          // Swal.close();
          // Close();
        } else if (TipoDeRepeticao === "Semanalmente") {
          for (let dia_semana of diasSelecionados) {
            if (dia_semana === data1.dia_semana.slice(0, 3)) {
              do {
                await HandleApiCall(data1);
                data1.hora_inicio = Texts.STATIC_OPC_HORARIOS_INICIO[
                  Texts.STATIC_OPC_HORARIOS_INICIO.indexOf(
                    `${data1.hora_inicio}:00`
                  ) + 1
                ]?.slice(0, 5);
                data1.hora_fim = Texts.STATIC_OPC_HORARIOS_FIM[
                  Texts.STATIC_OPC_HORARIOS_FIM.indexOf(
                    `${data1.hora_fim}:00`
                  ) + 1
                ]?.slice(0, 5);
              } while (
                data1.hora_fim !==
                  Texts.STATIC_OPC_HORARIOS_FIM[
                    Texts.STATIC_OPC_HORARIOS_FIM.indexOf(
                      `${data.hora_fim}:00`
                    ) + 1
                  ]?.slice(0, 5) &&
                data1.hora_fim !== undefined
              );
            }
          }
        } else {
          do {
            await HandleApiCall(data1);

            data1.hora_inicio = Texts.STATIC_OPC_HORARIOS_INICIO[
              Texts.STATIC_OPC_HORARIOS_INICIO.indexOf(
                `${data1.hora_inicio}:00`
              ) + 1
            ]?.slice(0, 5);
            data1.hora_fim = Texts.STATIC_OPC_HORARIOS_FIM[
              Texts.STATIC_OPC_HORARIOS_FIM.indexOf(`${data1.hora_fim}:00`) + 1
            ]?.slice(0, 5);
          } while (
            data1.hora_fim !==
              Texts.STATIC_OPC_HORARIOS_FIM[
                Texts.STATIC_OPC_HORARIOS_FIM.indexOf(`${data.hora_fim}:00`) + 1
              ]?.slice(0, 5) &&
            data1.hora_fim !== undefined
          );

          Toast.fire({
            title: "Agendamento realizado com sucesso!",
            icon: "success",
            timer: 2500,
          });
          Swal.close();
          Close();
          return;
        }

        if (TipoDeRepeticao === "Diariamente") {
          dataReserva.setDate(
            Number(dataReserva.getDate()) + Number(RepetirACada)
          );
          data1.hora_inicio = HoraInicio.slice(0, 5);
          data1.hora_fim = Texts.STATIC_OPC_HORARIOS_FIM[
            Texts.STATIC_OPC_HORARIOS_FIM.indexOf(`${HoraInicio}`) + 1
          ].slice(0, 5);
          data1.dia_semana = diasDaSemana[dataReserva.getDay()] || "Dom";
        } else {
          dataReserva.setDate(Number(dataReserva.getDate()) + 1);
          data1.hora_inicio = HoraInicio.slice(0, 5);
          data1.hora_fim = Texts.STATIC_OPC_HORARIOS_FIM[
            Texts.STATIC_OPC_HORARIOS_FIM.indexOf(`${HoraInicio}`) + 1
          ].slice(0, 5);
          data1.dia_semana =
            diasDaSemana[dataReserva.getDay()].slice(0, 3) || "Dom";
          if (dataReserva.getDay() === 0 && flag === 1) {
            dataReserva.setDate(
              Number(dataReserva.getDate() + 7 * (RepetirACada - 1))
            );
          }
          flag = 1;
        }
      }
      Swal.close();
      Close();
    } else {
      let flag = 0;
      while (dataReserva.getTime() <= diafinal.getTime()) {
        const diasSelecionados = [];
        DiasSelecionados.map((e) => diasSelecionados.push(e.slice(0, 3)));

        if (TipoDeRepeticao === "Diariamente") {
          await HandleApiCall(data);
        } else if (TipoDeRepeticao === "Semanalmente") {
          for (let dia_semana of diasSelecionados) {
            if (dia_semana === data.dia_semana.slice(0, 3)) {
              await HandleApiCall(data);
            }
          }
        } else {
          await HandleApiCall(data);

          Toast.fire({
            title: "Agendamento realizado com sucesso!",
            icon: "success",
            timer: 2500,
          });
          Swal.close();
          Close();
          return;
        }

        if (TipoDeRepeticao === "Diariamente") {
          dataReserva.setDate(
            Number(dataReserva.getDate()) + Number(RepetirACada)
          );
          data.hora_inicio = HoraInicio.slice(0, 5);
          data.hora_fim = Texts.STATIC_OPC_HORARIOS_FIM[
            Texts.STATIC_OPC_HORARIOS_FIM.indexOf(`${HoraInicio}`) + 1
          ].slice(0, 5);
          data.dia_semana = diasDaSemana[dataReserva.getDay()] || "Dom";
        } else {
          dataReserva.setDate(Number(dataReserva.getDate()) + 1);
          data.hora_inicio = HoraInicio.slice(0, 5);
          data.hora_fim = Texts.STATIC_OPC_HORARIOS_FIM[
            Texts.STATIC_OPC_HORARIOS_FIM.indexOf(`${HoraInicio}`) + 1
          ].slice(0, 5);
          data.dia_semana =
            diasDaSemana[dataReserva.getDay()].slice(0, 3) || "Dom";
          if (dataReserva.getDay() === 0 && flag === 1) {
            dataReserva.setDate(
              Number(dataReserva.getDate() + 7 * (RepetirACada - 1))
            );
          }
          flag = 1;
        }
      }
      Toast.fire({
        title: "Agendamento realizado com sucesso!",
        icon: "success",
        timer: 2500,
      });
      Swal.close();
      Close();
    }
  }

  async function Close() {
    setDisabled(false);
    setShow(false);
    setTitulo("");
    setHoraInicio("");
    setHoraFim("");
    setRepetir(false);
    setTipodeRepeticao(Texts.STATIC_OPC_REPETIR[0]);
    setDataEncerramento(new Date(dataReserva));
    setSelecionados([]);
    setIsChecked(false);
    setIdDestinatarios([]);
    setObservacao("");
  }

  const aoSelecionarPart = (e) => {
    const partSelecionado = e.target.value;

    for (let dado of DadosParticipantes) {
      if (dado.nome === partSelecionado && !IdDestinatarios.includes(dado.id)) {
        setIdDestinatarios([dado.id, ...IdDestinatarios]);
      }
    }

    setSelecionados((prevEnviarPara) => {
      if (!prevEnviarPara.includes(partSelecionado)) {
        return [partSelecionado, ...prevEnviarPara];
      }
      return prevEnviarPara;
    });
  };

  function removeUserParti(user) {
    const userObj = DadosParticipantes.find((e) => e.nome === user);
    if (!userObj) return;

    const id = userObj.id;
    const name = userObj.nome;

    const indexId = IdDestinatarios.indexOf(id);
    if (indexId === -1) return;

    const newEnviarPara = Selecionados.filter((item) => item !== name);
    const newIdDestinatario = IdDestinatarios.filter((item) => item !== id);
    setIdDestinatarios(newIdDestinatario);
    setSelecionados(newEnviarPara);
  }

  const display = Repetir === false ? "none" : "flex";

  let prazoMax = maximo();
  prazoMax = format({ DataInicial: prazoMax, Formato: "YYYY-MM-DD" });

  let exibirTexto =
    DataEncerramento !== "" &&
    Repetir === true &&
    TipoDeRepeticao !== "Não se repete"
      ? "flex"
      : "none";
  
  return (
    <>
      <Background Show={Show}>
        <Form onSubmit={HandleSubmit}>
          <CloseIcon onClick={() => Close()} />

          <Title>
            Agendamento {format({ DataInicial: dataReserva, Formato: "DD/MM/YYYY" })}
          </Title>

          <div className="titulo">
            <Pencil />
            <InputLabel
              value={Titulo}
              onChange={(e) => setTitulo(e.target.value)}
              type="text"
              label="Título"
              required
              maxLength={50}
            />
          </div>

          <div className="horarios">
            <Clock />
            <div className="horario-input">
              <SelectLabel
                options={Texts?.STATIC_OPC_HORARIOS_INICIO.map((e) =>
                  e.slice(0, 5)
                )}
                values={Texts?.STATIC_OPC_HORARIOS_INICIO}
                label="Início"
                value={HoraInicio}
                onChange={(e) => setHoraInicio(e.target.value)}
                required
              />
              <SelectLabel
                options={Texts?.STATIC_OPC_HORARIOS_FIM.map((e) =>
                  e.slice(0, 5)
                )}
                values={Texts?.STATIC_OPC_HORARIOS_FIM}
                label="Fim"
                value={HoraFim}
                onChange={(e) => setHoraFim(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="check-repetir">
            <input
              type="checkbox"
              className="check-repeticao"
              onChange={handleOnChangeRepeat}
              checked={Repetir}
            />
            <label style={{ marginLeft: "10px", color: 'var(--primary-font-color)' }}>Repetir?</label>
          </div>

          <div style={{ display: `${display}` }} className="selectRepeticao">
            <SelectLabel
              label="Quando se Repete?"
              required={Repetir === false ? false : true}
              values={Texts.STATIC_OPC_REPETIR}
              options={Texts.STATIC_OPC_REPETIR}
              value={TipoDeRepeticao}
              onChange={(e) => setTipodeRepeticao(e.target.value)}
            />
          </div>

          <div
            style={{
              display: TipoDeRepeticao === "Não se repete" ? "none" : "flex",
              width: "100%",
            }}
          >
            {TipoDeRepeticao === "Diariamente" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginRight: "auto",
                }}
              >
                <TipoDiario
                  repetirACada={RepetirACada}
                  setRepetirACada={setRepetirACada}
                  dataReserva={dataReserva}
                  DataEncerramento={DataEncerramento}
                  setDataEncerramento={setDataEncerramento}
                  exibirTexto={exibirTexto}
                  prazoMax={prazoMax}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginRight: "auto",
                }}
              >
                <TipoSemanal
                  repetirACada={RepetirACada}
                  setRepetirACada={setRepetirACada}
                  dataReserva={dataReserva}
                  DataEncerramento={DataEncerramento}
                  setDataEncerramento={setDataEncerramento}
                  exibirTexto={exibirTexto}
                  prazoMax={prazoMax}
                  setDiasSelecionados={setDiasSelecionados}
                  diasSelecionados={DiasSelecionados}
                />
              </div>
            )}
          </div>

          <div className="check-add">
            <input
              type="checkbox"
              className="check-adicionar"
              checked={isChecked}
              onChange={handleOnChange}
            />
            <label style={{ color: 'var(--primary-font-color)' }}>Adicionar participantes?</label>
          </div>

          <div className="participantes">
            <PersonAdd />
            <SelectLabel
              value={IdDestinatarios}
              onChange={aoSelecionarPart}
              defaultValue="Selecione o usuário"
              options={Destinatarios}
              values={Destinatarios}
              disabled={!isChecked}
            />
          </div>
          <div
            style={{
              display: Selecionados.length === 0 ? "none" : "flex",
              width: "80%",
            }}
            className="listaParticipantes"
          >
            <ul
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "10px",
                justifyItems: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              {Selecionados.map((e) => (
                <li onClick={() => removeUserParti(e)}>{e.slice(0, 10)}</li>
              ))}
            </ul>
          </div>
          <div
            style={{
              display: "flex",
              gap: "7%",
              justifyContent: "center",
              marginBottom: "2%",
            }}
          >
            <Button
              type="button"
              style={{
                backgroundColor: Disabled
                  ? "grey"
                  : "var(--table-primary-color)",
              }}
              onClick={() => setShowFileInput(true)}
              disabled={Disabled}
            >
              Anexo
            </Button>
            <Button
              type="button"
              style={{
                backgroundColor: Disabled
                  ? "grey"
                  : "var(--table-primary-color)",
              }}
              
              onClick={() => setShowModalObservacao(!ShowModalObservacao)}
              disabled={Disabled}
            >
              Observação
            </Button>
            <Button type="submit" disabled={Disabled}>
              Salvar
            </Button>
          </div>
        </Form>
        <FileInput
          setShow={setShowFileInput}
          Show={ShowFileInput}
          Anexo={Anexo}
          setAnexo={setAnexo}
          Origin={"Agendamento"}
        />
      </Background>
      <Observação
        ShowModalObservacao={ShowModalObservacao}
        setShowModalObservacao={setShowModalObservacao}
        Observacao={Observacao}
        setObservacao={setObservacao}
      />
    </>
  );
}
