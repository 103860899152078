import React, { useState } from "react";
import { Background, Form, CloseIcon, Title, Button } from "./style";
import InputLabel from "../../../InputLabel";
import api from "../../../../services/api";
import { Swal, Toast } from "../../../../services/SwalTemplates";
// import { Navigate } from "react-router-dom";

export default function EditRoom({
  Show,
  setShow,
  IdLocal,
  setIdLocal,
  Nome,
  setNome,
  Lotacao,
  setLotacao,
  PrazoAntecedencia,
  setPrazoAntecedencia,
  Deletado,
  setDeletado,
  Editado,
  setEditado,
}) {
  const [AuxNome, setAuxNome] = useState(Nome);
  const [AuxLotacao, setAuxLotacao] = useState(Lotacao);
  const [AuxPrazoAntecedencia, setAuxPrazoAntecedencia] =
    useState(PrazoAntecedencia);

  async function HandleSubmit(e) {
    e.preventDefault();

    const data = {
      descricao: AuxNome,
      capacidade_pessoas: AuxLotacao,
      prazo: AuxPrazoAntecedencia,
    };

    api
      .patch(`/locais/${IdLocal}`, { data })
      .then((response) => {
        setNome(AuxNome);
        setLotacao(AuxLotacao);
        setPrazoAntecedencia(AuxPrazoAntecedencia);
        Toast.fire({
          title: response?.data?.sucess,
          icon: "success",
          timer: 2500,
        });
        setEditado(true);
        Close();
      })
      .catch((e) => {
        Close();
        console.log(e);
        Swal.fire({
          title: "Atenção!",
          text: "Error",
          icon: "error",
          timer: 2500,
        });
      });
  }

  async function Close() {
    setShow(false);
  }

  function excluirLocal() {
    Swal.fire({
      title: `Deseja excluir este local?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--deep-carmine-pink)",
      cancelButtonColor: "gray",
      confirmButtonText: "DELETAR",
      cancelButtonText: "CANCELAR",
    }).then((result) => {
      if (result.isConfirmed) {
        api.delete(`/locais/${IdLocal}}`).then((res) => {
          Swal.fire(`Local excluído.`, "", "success");
          setDeletado(true);
        });
      }
    });
  }

  return (
    <Background Show={Show}>
      <Form onSubmit={HandleSubmit}>
        <CloseIcon onClick={() => Close()} />
        <Title>Editar {Nome}</Title>
        <InputLabel
          type="text"
          value={AuxNome}
          onChange={(e) => setAuxNome(e.target.value)}
          label="Descrição do Espaço: "
          required
        />
        <InputLabel
          type="text"
          value={AuxLotacao}
          onChange={(e) => setAuxLotacao(e.target.value)}
          label="Lotação Máx.: "
          required
        />
        <InputLabel
          type="text"
          value={AuxPrazoAntecedencia}
          onChange={(e) => setAuxPrazoAntecedencia(e.target.value)}
          label="Pode ser reservado com antecedência de quantos dias?"
          required
        />
        <div style={{ display: "flex", gap: "20px" }}>
          <Button
            type="button"
            style={{ backgroundColor: "grey" }}
            onClick={() => excluirLocal()}
          >
            Excluir
          </Button>
          <Button type="submit">Salvar</Button>
        </div>
      </Form>
    </Background>
  );
}
