import { useEffect, useRef } from "react";
import { Table } from "./style";
import Texts from "../../../assets/json/texts.json";
import api from "../../../services/api";
import { format } from "../../../services/DateFormatter";

export default function CalendarWeek({
  setHorario,
  idLocal,
  setDataReserva,
  dataReserva,
  ShowModalAgendar,
  setShowModalAgendar,
  ShowModalExibirAgendamento,
  setShowModalExibirAgendamento,
  DadosAgendamento,
  setDadosAgendamento,
  Week,
  setWeek,
  MesAtual,
  setMesAtual,
  AnoAtual,
  setAnoAtual,
  Dados,
  setDados,
  getWeek,
}) {
  const handleClick = (hora, index) => {
    setHorario(hora);
    setDataReserva(Week[index]);
    setShowModalAgendar(!ShowModalAgendar);
  };

  const tableRefs = useRef([]);
  const WIDTH_VALUE = {
    width_1760: 6,
    width_1472: 5,
    width_1216: 4,
    width_960: 2,
    width_672: 1,
  };

  useEffect(() => {
    for (let day of Week) {
      if (day > Week[6]) {
        setWeek(getWeek(Week[6]));
        break;
      }
    }

    api
      .get(
        `/agendamentos/${idLocal}/${format({
          DataInicial: Week[0],
          Formato: "YYYY-MM-DD",
        })}/${format({
          DataInicial: Week[6],
          Formato: "YYYY-MM-DD",
        })}`
      )
      .then((response) => {
        setDados(response.data);
      })
      .catch((error) => console.error(error));
  }, [ShowModalAgendar, ShowModalExibirAgendamento, Week, idLocal]);

  useEffect(() => {
    const now = new Date();
    const currentHour = `${now.getHours()}:00`;
    const currentRowIndex = Texts.STATIC_OPC_HORARIOS_INICIO.findIndex(
      (hora) => hora.slice(0, 2) === currentHour.slice(0, 2)
    );

    for (let day of Week) {
      if (
        currentRowIndex >= 0 &&
        tableRefs.current[currentRowIndex] &&
        `${day.getDate()}/${day.getMonth()}/${day.getFullYear()}` ===
          `${now.getDate()}/${now.getMonth()}/${now.getFullYear()}`
      ) {
        tableRefs.current[currentRowIndex].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        break;
      }
    }
  }, [Week, Dados]);

  return (
    <main>
      <Table>
        <thead
          style={{
            position: "sticky",
            top: 0,
            backgroundColor: "var(--table-primary-color)",
            width: "100%",
            border: "2px solid white",
          }}
        >
          <tr>
            <th style={{ width: "5%" }}>Hora</th>
            {Texts?.STATIC_OPC_DIAS_SEMANA.map((dia, index) => (
              <th
                style={{
                  width: "10.5%",
                  border: "1px solid var(--secondary-bg-color)",
                }}
                key={dia}
              >
                <h5>{dia.substring(0, 3)}.</h5>
                <h2>
                  {Week[index].getDate()}/{Week[index].getMonth() + 1}
                </h2>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Texts.STATIC_OPC_HORARIOS_INICIO.map((hora, i) => {
            const horaFinal = Texts.STATIC_OPC_HORARIOS_FIM[i];
            return (
              <tr
                key={hora}
                ref={(el) => {
                  let valor;

                  switch (true) {
                    case window.innerWidth >= 1760:
                      valor = WIDTH_VALUE.width_1760;
                      break;
                    case window.innerWidth >= 1472:
                      valor = WIDTH_VALUE.width_1472;
                      break;
                    case window.innerWidth >= 1216:
                      valor = WIDTH_VALUE.width_1216;
                      break;
                    case window.innerWidth >= 960:
                      valor = WIDTH_VALUE.width_960;
                      break;
                    case window.innerWidth >= 672:
                      valor = WIDTH_VALUE.width_672;
                      break;
                    default:
                      valor = WIDTH_VALUE.width_672;
                      break;
                  }
                  return (tableRefs.current[i - valor] = el);
                }}
              >
                <td style={{ width: "8%" }}>{`${hora.slice(
                  0,
                  5
                )} - ${horaFinal.slice(0, 5)}`}</td>
                {Week.map((dayWeek, index) => {
                  const reserva = Dados.agendamentos?.filter(
                    (d) =>
                      d.dia_semana ===
                        Texts.STATIC_OPC_DIAS_SEMANA[
                          new Date(dayWeek).getDay()
                        ].slice(0, 3) &&
                      (d.hora_inicio === hora || d.hora_fim === horaFinal)
                  );

                  let dataHojeComparacao = new Date();
                  let dataSemana = new Date(
                    Week[index].getFullYear(),
                    Week[index].getMonth(),
                    Week[index].getDate()
                  );
                  let dataHoje = new Date(
                    dataHojeComparacao.getFullYear(),
                    dataHojeComparacao.getMonth(),
                    dataHojeComparacao.getDate()
                  );
                  const isPast = dataSemana < dataHoje;
                  const isPastTime =
                    dataSemana.toDateString() === dataHoje.toDateString() &&
                    hora <=
                      `${dataHojeComparacao.getHours()}:${dataHojeComparacao.getMinutes()}`;

                  return reserva !== undefined && !!reserva[0]?.titulo ? (
                    <td
                      key={index}
                      className="dados"
                      onClick={() => {
                        setShowModalExibirAgendamento(
                          !ShowModalExibirAgendamento
                        );
                        setDadosAgendamento(reserva[0]);
                      }}
                      title="Clique para saber mais"
                    >
                      {String(reserva[0]?.titulo).slice(0, 20) + "..."}
                    </td>
                  ) : isPast || isPastTime ? (
                    <td
                      key={index}
                      className="indisponivel"
                      title="Indisponível"
                    ></td>
                  ) : (
                    <td
                      key={index}
                      onClick={() => handleClick(hora, index)}
                      className="semDados"
                      title="Horário disponível"
                    ></td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </main>
  );
}
