import styled from "styled-components";
import { LuPencil } from "react-icons/lu";
import { IoPersonAdd } from "react-icons/io5";
import { FaCalendarDay } from "react-icons/fa";
import { ImClock } from "react-icons/im";
import { CiRepeat } from "react-icons/ci";
import { IoClose } from "react-icons/io5";

export const Title = styled.h1`
  margin-bottom: 35px;
  font-size: 50px;
`;

export const Background = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: ${(props) => (props.Show ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;

  &:before {
    z-index: 1;
    content: "";
    width: 100%;
    height: 100%;
    background-color: var(--blur-bg-color);
    position: fixed;
    top: 0;
    left: 0;
  }

  @media screen and (max-width: 768px) {
    justify-content: flex-start;
  }
`;

export const Container = styled.div`
  align-items: center;
  background-color: var(--secondary-bg-color);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 1440px) {
    padding: 15px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  padding: 50px 50px 20px;
  border-radius: 5px;
  background-color: var(--secondary-bg-color);
  gap: 20px;

  .titulo,
  .horarios,
  .data,
  .participantes,
  .check-add {
    display: flex;
    gap: 10px;
    width: 100%;
    .horario-input {
      display: flex;
      width: 50%;
      gap: 10px;
    }
    .add {
      display: flex;
      width: 100%;
      gap: 10px;
    }
  }
  .repetir {
    display: flex;
    margin-right: auto;
    gap: 10px;
    width: 40%;
  }
  
  .check-add {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-size: 16px;
  }
  .check-repetir{
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-right:auto;
  }
  .check-repeticao{
    font-size: 5px;
    transform: scale(1.5);
    
  }
  label {
    padding-left: 10px;
  }
  .check-adicionar {
    font-size: 5px;
    transform: scale(1.5);
  }

  .selectRepeticao{
    width:50%;
    margin-right:auto;
    margin-left:25px;
  }
  
  .listaSemana{
    ul{
      display:flex;
      flex-direction:row;
      justify-content:space-between;
      width:40%;
      list-style-type: none;
      margin-left:4%;
      margin-bottom:3%
    }
    li{
      width:25px;
      height:25px;
      align-content:center;
      text-align:center;
      border-radius:50%;
      border:1px solid black;
      cursor:pointer;
    }
  }
  .listaParticipantes{
    margin-right:10%;
    li{
      border:1px solid var(--second-auxiliary-color);;
      text-align:center;
      border-radius:20px;
      width:100%;
      height:25px;
      list-style-type:none;
      align-content:center;
      color: var(--second-auxiliary-color);
    }
    li:hover{
      cursor: pointer;
      border:2px solid red;
      background-color:red;
      color:white;
      font-weight:600;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 50px 20px 20px;
  }
`;

export const Button = styled.button`
  border: none;
  align-self: center;
  height: 40px;
  border-radius: 5px;
  padding: 10px 40px;
  background-color: var(--main-color);
  color: var(--button-text-color);
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 800;
  transition: transform 0.5s;
  transition: box-shadow 0.5s;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  }
`;

export const CloseIcon = styled(IoClose)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 40px;
  transition: transform 0.2s;
  color: var(--second-auxiliary-color);

  &:hover {
    transform: scale(1.2);
  }
`;

export const Clock = styled(ImClock)`
  color: var(--second-auxiliary-color);
  align-self: center;
`;
export const Pencil = styled(LuPencil)`
  color: var(--second-auxiliary-color);
  align-self: center;
`;
export const PersonAdd = styled(IoPersonAdd)`
  color: var(--second-auxiliary-color);  
  align-self: center;
`;
export const Repeat = styled(CiRepeat)`
  color: var(--second-auxiliary-color);  
  align-self: center;
`;
export const Calendar = styled(FaCalendarDay)`
  color: var(--second-auxiliary-color);  
  align-self: center;
  margin-right: 15px;
`;
