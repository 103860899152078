import styled from "styled-components";

export const Container = styled.div`
  height: fit-content;
  padding:0;
  min-height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  gap: 20px;
  margin-top: 20px;
  .titulo {
    width: 100%;
    text-align: center;
    font-size: 22px;
    font-family: monospace;
    color:var(--primary-font-color);
    @media (max-width: 554px) {
    .titulo{
      font-size:1px;
    }
  }
  }

  .conteudo {
    display: inline-grid;
    grid-template-columns: 0.7fr 0.7fr 0.7fr 0.7fr ;
    max-width: 100%;
    width: 90%;
    gap:10px;
    justify-items:center;
    justify-content:center;
  }

  @media (max-width: 1590px) {
    .conteudo{
      grid-template-columns: 0.7fr 0.7fr 0.7fr ;
    }
  }
  @media (max-width: 1210px) {
    .conteudo{
      grid-template-columns: 50% 50% ;
    }
  }

  @media (max-width: 855px) {
    .conteudo{
      grid-template-columns: 1fr 1fr 1fr;
      align-items:center;
      gap:5px;
      margin-top: 0px;
    }
    
  }
  @media (max-width: 720px) {
    .conteudo{
      grid-template-columns: 0.7fr 0.7fr 0.7fr ;
      align-items:center;
    }
    
  }
  @media (max-width: 586px) {
    .conteudo{
      grid-template-columns: 0.7fr 0.7fr ;
      align-items:center;
    }
    
  }
  
  @media (max-width: 401px) {
    .conteudo{
      grid-template-columns: 0.7fr;
      align-items:center;
    }
    
  }
  @media (max-width: 635px) {
    .titulo{
      font-size:20px;
    }
  }
`;
