import React from "react";
import TextareaLabel from "../../TextareaLabel"
import { Container, Button, Background } from "./style";

export default function Observação({
  ShowModalObservacao, 
  setShowModalObservacao, 
  setObservacao, 
  Observacao
}){

  let numChars = Observacao?.length === 0 || !Observacao?.length ? "0/100 caracteres" : `${Observacao?.length}/100 caracteres`

  function Close(){
    setShowModalObservacao(!ShowModalObservacao);
    setObservacao("");
  }

  return(
    <Background Show={ShowModalObservacao}>
      <Container>
        <TextareaLabel
          label="Observação sobre o agendamento"
          type="text"
          maxLength={100}
          value={Observacao}
          onChange={(e) => setObservacao(e.target.value)}
        />
        <p style={{color: "var(--second-auxiliary-color)"}}>{numChars}</p>
        <div className="buttons">
          <Button onClick={() => Close()}>Cancelar</Button>
          <Button onClick={() => setShowModalObservacao(!ShowModalObservacao)}>Salvar</Button>
        </div>
        
      </Container>
    </Background>
  )
}