import { useState, useEffect } from "react";
import {
  Background,
  Container,
  Title,
  ScrollTable,
  TypeCalendar,
  ConfigIcon,
  BackIcon,
  Table,
  PreviousDouble,
  PreviousOne,
  NextDouble,
  NextOne,
} from "./style";
import { useLocation, useNavigate } from "react-router-dom";
import GoBack from "../../../components/GoBack";
import ModalAgendar from "../../../components/Modal/Agendar";
import CalendarDay from "../../../components/Calendar/CalendarDay";
import CalendarWeek from "../../../components/Calendar/CalendarWeek";
import CalendarMonth from "../../../components/Calendar/CalendarMonth";
import ModalExibirAgendamento from "../../../components/Modal/ExibirAgendamento";
import ModalEditRoom from "../../../components/Modal/Gestor/EditRoom";
import { TokenPerfil } from "../../../services/TokenData";
import Texts from "../../../assets/json/texts.json";

export default function Reserve() {
  const [ShowModalAgendar, setShowModalAgendar] = useState(false);
  const Location = useLocation();
  const date =
    Location?.state?.dataPretendida === "00-00-0000"
      ? new Date()
      : new Date(Location?.state?.dataPretendida);
  const datePorFiltro =
    Location?.state?.dataPretendida === "00-00-0000" ? 0 : 1;
  const [Dia, setDia] = useState(date.getDate() + datePorFiltro);
  const [Mes, setMes] = useState(date.getMonth() + 1);
  const [Ano, setAno] = useState(date.getFullYear());
  //
  const [Week, setWeek] = useState(getWeek(new Date()));
  const [MesAtual, setMesAtual] = useState(new Date().getMonth() + 1);
  const [AnoAtual, setAnoAtual] = useState(new Date().getFullYear());
  const [Dados, setDados] = useState([]);
  //
  const [TipoDeCalendario, setTipoDeCalendario] = useState(
    Location?.state?.filtroAtivo === true ? "diario" : "mensal"
  );
  const [Nome, setNome] = useState(Location?.state?.name);
  const [IdLocal, setIdLocal] = useState(Location?.state?.idLocal);
  const [PrazoAntecedencia, setPrazoAntecedencia] = useState(
    Location?.state?.prazo_antecedencia
  );
  const [Lotacao, setLotacao] = useState(Location?.state?.lotacao);
  const [HorarioReserva, setHorarioReserva] = useState("");
  const [DataReserva, setDataReserva] = useState("");
  const [DiaDaSemana, setDiaDaSemana] = useState(date.getDay() + datePorFiltro);
  const [ShowModalExibirAgendamento, setShowModalExibirAgendamento] =
    useState(false);
  const [DadosAgendamento, setDadosAgendamento] = useState([]);
  const [ShowModalEditRoom, setShowModalEditRoom] = useState(false);
  const [Deletado, setDeletado] = useState(false);
  const [Editado, setEditado] = useState(false);
  const [Reload, setReload] = useState(false);
  const Navigate = useNavigate();

  useEffect(() => {
    if (Deletado || Editado) {
      Navigate(-1);
    }
  }, [Deletado, Editado, Navigate, Reload]);

  useEffect(() => {}, [TipoDeCalendario, ShowModalAgendar]);

  function updateDate(days) {
    const newDate = new Date(Ano, Mes - 1, Dia);
    newDate.setDate(newDate.getDate() + days);
    setAno(newDate.getFullYear());
    setMes(newDate.getMonth() + 1);
    setDia(newDate.getDate());
    setDiaDaSemana(newDate.getDay());
    setDataReserva(newDate);
  }

  function previousWeek() {
    setDados([]);

    const firstDayOfWeek = Week[0];
    const newFirstDay = new Date(firstDayOfWeek);

    newFirstDay.setDate(firstDayOfWeek.getDate() - 7);
    setWeek(getWeek(newFirstDay));
    setMesAtual(newFirstDay.getMonth() + 1);
    setAnoAtual(newFirstDay.getFullYear());
  }

  function nextWeek() {
    setDados([]);

    const firstDayOfWeek = Week[0];
    const newFirstDay = new Date(firstDayOfWeek);

    newFirstDay.setDate(firstDayOfWeek.getDate() + 7);
    setWeek(getWeek(newFirstDay));
    setMesAtual(newFirstDay.getMonth() + 1);
    setAnoAtual(newFirstDay.getFullYear());
  }

  function getWeek(fromDate) {
    const sunday = new Date(fromDate);
    sunday.setDate(fromDate.getDate() - fromDate.getDay());
    const result = [new Date(sunday)];

    while (sunday.setDate(sunday.getDate() + 1) && sunday.getDay() !== 0) {
      result.push(new Date(sunday));
    }
    return result;
  }

  return (
    <Background>
      <div style={{ display: TipoDeCalendario !== "mensal" ? "none" : "flex" }}>
        <GoBack />
      </div>
      <Container>
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div className="headerPag">
            <BackIcon
              TipoDeCalendario={TipoDeCalendario}
              onClick={() => {
                setTipoDeCalendario("mensal");
              }}
            ></BackIcon>
          </div>
          <Title>
            <div
              style={{ display: TokenPerfil() !== "GESTOR" ? "none" : "flex" }}
            >
              <ConfigIcon
                onClick={() => {
                  setShowModalEditRoom(true);
                }}
              />
            </div>
            <h1>{` Reservar ${Nome}`}</h1>
          </Title>
          <div
            className="buttons"
            style={{ marginLeft: "auto", marginRight: "5%" }}
          >
            <div className="tipoCalendario">
              <TypeCalendar onClick={(x) => setTipoDeCalendario("mensal")}>
                Mensal
              </TypeCalendar>
              <TypeCalendar onClick={(x) => setTipoDeCalendario("semanal")}>
                Semanal
              </TypeCalendar>
              <TypeCalendar onClick={(x) => setTipoDeCalendario("diario")}>
                Diário
              </TypeCalendar>
            </div>
          </div>
        </div>
        <>
          {TipoDeCalendario === "diario" ? (
            <Table>
              <caption>
                {Texts?.STATIC_MESES[Mes - 1]} / {Ano}
              </caption>
              <thead>
                <tr className="headLine" style={{ width: "100%" }}>
                  <th
                    style={{
                      borderRight: "1px solid var(--secondary-bg-color)",
                      width: "12%",
                    }}
                  >
                    Hora
                  </th>
                  <th className="conteudoHeader" style={{ width: "90%" }}>
                    <PreviousDouble
                      title="Voltar uma semana"
                      onClick={() => updateDate(-7)}
                    />
                    <PreviousOne
                      title="Voltar uma dia"
                      onClick={() => updateDate(-1)}
                    />
                    <h4 style={{ minWidth: "15%" }}>
                      {Texts.STATIC_OPC_DIAS_SEMANA[DiaDaSemana]} | Dia {Dia}
                    </h4>
                    <NextOne
                      title="Avançar um dia"
                      onClick={() => updateDate(1)}
                    />
                    <NextDouble
                      title="Avançar uma semana"
                      onClick={() => updateDate(7)}
                    />
                  </th>
                </tr>
              </thead>
            </Table>
          ) : TipoDeCalendario === "semanal" ? (
            <Table>
              <caption>
                {Texts?.STATIC_MESES[MesAtual - 1]} / {AnoAtual}
              </caption>
              <thead>
                <tr>
                  <th></th>
                  <th
                    style={{
                      padding: "0px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "90%",
                      height: "50px",
                    }}
                  >
                    <PreviousOne
                      title="Voltar uma semana"
                      onClick={previousWeek}
                    />
                    <h4 style={{ minWidth: "15%" }}>
                      Semana {Week[0].getDate()}/{Week[0].getMonth() + 1} -{" "}
                      {Week[6].getDate()}/{Week[6].getMonth() + 1}
                    </h4>
                    <NextOne title="Avançar uma semana" onClick={nextWeek} />
                  </th>
                </tr>
              </thead>
            </Table>
          ) : (
            <></>
          )}
        </>

        {/* <div className="calendarMonth"></div> */}
        <ScrollTable
          style={{ maxHeight: TipoDeCalendario === "mensal" ? "80vh" : "60vh" }}
          TipoDeCalendario={TipoDeCalendario}
        >
          <>
            {TipoDeCalendario === "mensal" ? (
              <CalendarMonth
                idLocal={IdLocal}
                setDataReserva={setDataReserva}
                setTipoDeCalendario={setTipoDeCalendario}
                Dia={Dia}
                setDia={setDia}
                Mes={Mes}
                setMes={setMes}
                Ano={Ano}
                setAno={setAno}
                DiaDaSemana={DiaDaSemana}
                setDiaDaSemana={setDiaDaSemana}
                DataReserva={DataReserva}
              />
            ) : TipoDeCalendario === "semanal" ? (
              <CalendarWeek
                setHorario={setHorarioReserva}
                dataReserva={DataReserva}
                setDataReserva={setDataReserva}
                idLocal={IdLocal}
                ShowModalAgendar={ShowModalAgendar}
                setShowModalAgendar={setShowModalAgendar}
                ShowModalExibirAgendamento={ShowModalExibirAgendamento}
                setShowModalExibirAgendamento={setShowModalExibirAgendamento}
                DadosAgendamento={DadosAgendamento}
                setDadosAgendamento={setDadosAgendamento}
                Reload={Reload}
                setReload={setReload}
                Week={Week}
                setWeek={setWeek}
                MesAtual={MesAtual}
                setMesAtual={setMesAtual}
                AnoAtual={AnoAtual}
                setAnoAtual={setAnoAtual}
                Dados={Dados}
                setDados={setDados}
                getWeek={getWeek}
              />
            ) : (
              <CalendarDay
                idLocal={IdLocal}
                setHorarioReserva={setHorarioReserva}
                Dia={Dia}
                setDia={setDia}
                Mes={Mes}
                setMes={setMes}
                Ano={Ano}
                setAno={setAno}
                DiaDaSemana={DiaDaSemana}
                setDiaDaSemana={setDiaDaSemana}
                ShowModalAgendar={ShowModalAgendar}
                setShowModalAgendar={setShowModalAgendar}
                ShowModalExibirAgendamento={ShowModalExibirAgendamento}
                setShowModalExibirAgendamento={setShowModalExibirAgendamento}
                DadosAgendamento={DadosAgendamento}
                setDadosAgendamento={setDadosAgendamento}
              />
            )}
          </>
        </ScrollTable>
        <div className="legendaReservas">
          <div className="disponivel">
            <span
              style={{
                border: "1px solid black",
                backgroundColor: "var(--mettalic-green)",
                color: "var(--mettalic-green)",
                fontSize: "20px",
              }}
            >
              _______
            </span>
            <span> Disponível</span>
          </div>
          <div className="reservado">
            <span
              style={{
                border: "1px solid black",
                backgroundColor: "var(--philippine-gray)",
                color: "var(--philippine-gray)",
                fontSize: "20px",
              }}
            >
              _______
            </span>
            <span> Reservado/Indisponível</span>
          </div>
        </div>
      </Container>
      <ModalAgendar
        Show={ShowModalAgendar}
        setShow={setShowModalAgendar}
        horario={HorarioReserva}
        setDataReserva={setDataReserva}
        dataReserva={DataReserva}
        idLocal={IdLocal}
        prazo={PrazoAntecedencia}
      />
      <ModalExibirAgendamento
        Show={ShowModalExibirAgendamento}
        setShow={setShowModalExibirAgendamento}
        Dados={DadosAgendamento}
        IdLocal={IdLocal}
        PrazoAntecedencia={PrazoAntecedencia}
        Reload={Reload}
        setReload={setReload}
      />
      <ModalEditRoom
        Show={ShowModalEditRoom}
        setShow={setShowModalEditRoom}
        IdLocal={IdLocal}
        setIdLocal={setIdLocal}
        Nome={Nome}
        setNome={setNome}
        Lotacao={Lotacao}
        setLotacao={setLotacao}
        PrazoAntecedencia={PrazoAntecedencia}
        setPrazoAntecedencia={setPrazoAntecedencia}
        Deletado={Deletado}
        setDeletado={setDeletado}
        Editado={Editado}
        setEditado={setEditado}
      />
    </Background>
  );
}
