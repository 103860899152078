import styled from "styled-components";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  color: var(--secondary-font-color);
  background-color: var(--table-primary-color);
  border-radius: 5px;
  margin-top: 10px;

  caption {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    color: var(--secondary-font-color);
    background-color: var(--table-primary-color);
    border-radius: 5px;
    font-size: 25px;
    padding: 10px;
    margin-bottom: 2px;
  }

  thead {
    height: 50px;
    width: 100% th {
      width: 100%;
      padding: 0px;
    }
  }

  .headLine {
    border-bottom: 2px solid var(--secondary-bg-color);
    width: 100%;
  }

  .conteudoHeader {
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    height: 50px;
    margin: 0 50% 0 45%;
    h4 {
      min-width: 15%;
    }
  }

  td,
  th {
    padding: 10px;
  }
  th {
    height: 30px;
  }
  tbody {
    td {
      border-right: 1px solid var(--secondary-bg-color);
    }

    .dados {
      text-align: left;
      background-color: var(--philippine-gray);
      color: white;
      border: 1px solid black;
      span {
        font-weight: 600;
      }
    }

    .dados:hover {
      cursor: help;
      background-color: var(--gray);
    }

    .semDados {
      background-color: var(--mettalic-green);
      border: 1px solid black;
    }
    .semDados:hover {
      cursor: pointer;
      background-color: var(--mettalic-green);
    }

    .indisponivel:hover {
      cursor: not-allowed;
      background-color: var(--table-primary-color);
    }

    td:hover {
      background-color: var(--table-secondary-color);
    }
  }

  tr {
    border: 1px solid var(--table-border-color);
  }
`;

export const PreviousOne = styled(MdKeyboardArrowLeft)`
  font-size: 25px;
  font-weight: 800;
  cursor: pointer;

  &:hover {
    font-size: 30px;
    background-color: var(--main-color);
  }
`;

export const NextOne = styled(MdKeyboardArrowRight)`
  font-size: 25px;
  font-weight: 800;
  cursor: pointer;

  &:hover {
    font-size: 30px;
    background-color: var(--main-color);
  }
`;
