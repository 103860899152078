import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  PreviousOne,
  PreviousDouble,
  NextDouble,
  NextOne,
} from "./style";
import Texts from "../../../assets/json/texts.json";
import api from "../../../services/api";
import { format } from "../../../services/DateFormatter";

export default function CalendarDay({
  idLocal,
  setHorarioReserva,
  Dia,
  setDia,
  Mes,
  setMes,
  setAno,
  Ano,
  setDiaDaSemana,
  ShowModalAgendar,
  setShowModalAgendar,
  ShowModalExibirAgendamento,
  setShowModalExibirAgendamento,
  setDadosAgendamento,
}) {
  const [Dados, setDados] = useState([]);
  const tableRefs = useRef([]);
  const WIDTH_VALUE = {
    width_1760: 6,
    width_1472: 5,
    width_1216: 4,
    width_960: 2,
    width_672: 1,
  };

  useEffect(() => {
    api
      .get(`/agendamentos/${idLocal}/${Ano}-${Mes}-${Dia}`)
      .then((response) => setDados(response.data.agendamentos));
  }, [ShowModalAgendar, ShowModalExibirAgendamento, Ano, Dia, Mes, idLocal]);

  const updateDate = (days) => {
    const newDate = new Date(Ano, Mes - 1, Dia);
    newDate.setDate(newDate.getDate() + days);
    setAno(newDate.getFullYear());
    setMes(newDate.getMonth() + 1);
    setDia(newDate.getDate());
    setDiaDaSemana(newDate.getDay());
  };

  useEffect(() => {
    const now = new Date(); // Pega a data de hoje
    const currentDate = new Date(Ano, Mes - 1, Dia); // Cria uma data com base nas variaveis passadas dentro do Date
    const currentHour = `${now.getHours()}:00`;
    const teste =
      `${currentDate.getDate()}/${currentDate.getMonth()}/${currentDate.getFullYear()}` ===
      `${now.getDate()}/${now.getMonth()}/${now.getFullYear()}`; // Compara o dia de hoje com a data criada com base nas variaveis

    const currentRowIndex = Texts.STATIC_OPC_HORARIOS_INICIO.findIndex(
      (hora) => hora.slice(0, 2) === currentHour.slice(0, 2)
    );

    if (currentRowIndex >= 0 && tableRefs.current[currentRowIndex] && teste) {
      tableRefs.current[currentRowIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [Ano, Dia, Mes, Dados]);

  const renderTableRow = (hora, i) => {
    const horaFinal = Texts.STATIC_OPC_HORARIOS_FIM[i];
    const reservasNoIntervalo = Dados.filter(
      (d) =>
        (d.hora_inicio <= hora && d.hora_fim > hora) ||
        (d.hora_inicio < horaFinal && d.hora_fim >= horaFinal)
    );
    const today = new Date();
    const currentDate = new Date(Ano, Mes - 1, Dia);

    let previousDay = new Date();
    previousDay = previousDay.setDate(previousDay.getDate() - 1);

    const isPast = currentDate < previousDay;
    const corFontReser = reservasNoIntervalo.length > 0 ? "white" : "white";
    const isPastTime =
      currentDate.toDateString() === today.toDateString() &&
      hora <= `${today.getHours()}:${today.getMinutes()}`;
    const corBackReser =
      reservasNoIntervalo.length > 0
        ? "var(--gray)"
        : isPast || isPastTime
        ? "var(--gray)"
        : "var(--la-salle-green)";

    return (
      <tr
        key={hora}
        ref={(el) => {
          let valor;

          switch (true) {
            case window.innerWidth >= 1760:
              valor = WIDTH_VALUE.width_1760;
              break;
            case window.innerWidth >= 1472:
              valor = WIDTH_VALUE.width_1472;
              break;
            case window.innerWidth >= 1216:
              valor = WIDTH_VALUE.width_1216;
              break;
            case window.innerWidth >= 960:
              valor = WIDTH_VALUE.width_960;
              break;
            case window.innerWidth >= 672:
              valor = WIDTH_VALUE.width_672;
              break;
            default:
              valor = WIDTH_VALUE.width_672;
              break;
          }
          return (tableRefs.current[i - valor] = el);
        }}
      >
        <td
          style={{
            backgroundColor: corBackReser,
            color: corFontReser,
            border: "1px solid var(--table-secondary-color)",
            textDecoration: isPastTime || isPast ? "line-through" : "none",
            textDecorationColor: isPastTime || isPast ? "black" : "white",
            textDecorationThickness: isPastTime || isPast ? "2px" : "",
            cursor: isPastTime || isPast ? "not-allowed" : "pointer",
          }}
          title={isPastTime || isPast ? "Indisponível" : "Horário disponível"}
          onClick={() => {
            if (!isPastTime && !isPast) {
              setShowModalAgendar(!ShowModalAgendar);
              setHorarioReserva(hora);
            }
          }}
        >
          {`${hora.slice(0, 5)} - ${horaFinal.slice(0, 5)}`}
        </td>
        {reservasNoIntervalo.length > 0
          ? reservasNoIntervalo.map((reserva, idx) => (
              <React.Fragment key={idx}>
                {["titulo", "nome", "observacao", "agendadoEm"].map(
                  (field, index) => (
                    <td
                      key={index}
                      className="dados"
                      onClick={() => {
                        setDadosAgendamento(reserva);
                        setShowModalExibirAgendamento(
                          !ShowModalExibirAgendamento
                        );
                      }}
                      title="Clique para saber mais"
                    >
                      {field === "observacao"
                        ? reserva[field] === null
                          ? "N/D"
                          : String(reserva[field]).slice(0, 25)
                        : field === "agendadoEm"
                        ? format({
                            DataInicial: reserva[field],
                            Formato: "DD/MM/YYYY",
                          })
                        : reserva[field]}
                    </td>
                  )
                )}
              </React.Fragment>
            ))
          : isPast || isPastTime
          ? Array(4).fill(
              <td className="indisponivel" title="Indisponível"></td>
            )
          : Array(4).fill(<td className="semDados"></td>)}
      </tr>
    );
  };

  return (
    <main>
      <Table>
        <thead>
          <tr
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: "var(--table-primary-color)",
              width: "100%",
              border: "2px solid white",
              fontWeight: "600",
            }}
          >
            <th style={{ width: "12%" }}>Inicio | Fim</th>
            <th>Descrição</th>
            <th>Reservado por</th>
            <th>Observação</th>
            <th>Reservado em</th>
          </tr>
        </thead>
        <tbody>{Texts.STATIC_OPC_HORARIOS_INICIO.map(renderTableRow)}</tbody>
      </Table>
    </main>
  );
}
