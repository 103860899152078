import styled from "styled-components";
import { IoClose } from "react-icons/io5";

export const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: ${(props) => (props.Show ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;

  &:before {
    z-index: 1;
    content: "";
    width: 100%;
    height: 100%;
    background-color: var(--blur-bg-color);
    position: fixed;
    top: 0;
    left: 0;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 35%;
  min-width:fit-content;
  height: 35%;
  display: flex;
  padding: 50px 50px 20px;
  border-radius: 5px;
  flex-direction: column;
  gap: 7px;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-bg-color);
  z-index: 2;
 
  p{
    margin-left:auto;
    margin-right:8%;
    font-size:13px;
  }
  .buttons{
    /* position: relative; */
    width: 60%;
    height: 35%;
    display: flex;
    /* padding: 50px 50px 20px;
    border-radius: 5px; */
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary-bg-color);
    /* z-index: 2; */
  }
`;
export const Button = styled.button`
  border: none;
  align-self: center;
  height: 40px;
  border-radius: 5px;
  padding: 10px 40px;
  background-color: var(--main-color);
  color: var(--button-text-color);
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 800;
  transition: transform 0.5s;
  transition: box-shadow 0.5s;
  margin-top: 3%;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  }
`;
export const CloseIcon = styled(IoClose)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 40px;
  transition: transform 0.2s;
  color: var(--second-auxiliary-color);

  &:hover {
    transform: scale(1.2);
  }
`;