import React, { useState } from "react";
import { Background, Form, CloseIcon, Title, Button } from "./style";
import InputLabel from "../../../InputLabel";
import api from "../../../../services/api";
import { Swal, Toast } from "../../../../services/SwalTemplates";

export default function AddRoom({ Show, setShow, tipo, idTipo, setAtualizaPag, AtualizaPag }) {
  const [Descricao, setDescricao] = useState("");
  const [CapacidadePessoas, setCapacidadePessoas] = useState();
  const [PrazoAntecedencia, setPrazoAntecedencia] = useState();
  const [Reload, setReload] = useState(false);
  async function HandleSubmit(e) {
    e.preventDefault();

    const data = {
      id_tipo: idTipo,
      descricao: Descricao,
      capacidade_pessoas: CapacidadePessoas,
      prazo: PrazoAntecedencia,
    };

    api
      .post("/locais", { data })
      .then((response) => {
        Toast.fire({
          title: response?.data?.sucess,
          icon: "success",
          timer: 2500,
        });
        setReload(!Reload);
        Close();
      })
      .catch((e) => {
        Close();
        console.log(e);
        Swal.fire({
          title: "Atenção!",
          text: "Error",
          icon: "error",
          timer: 2500,
        });
      });
  }
  async function Close() {
    setShow(false);
    setReload(!Reload);
    setAtualizaPag(!AtualizaPag)
  }

  return (
    <Background Show={Show}>
      <Form onSubmit={HandleSubmit}>
        <CloseIcon onClick={() => Close()} />
        <Title>Cadastrar {tipo}</Title>
        <InputLabel
          type="text"
          value={Descricao}
          onChange={(e) => setDescricao(e.target.value)}
          label="Descrição do Espaço: "
          required
        />
        <InputLabel
          type="text"
          value={CapacidadePessoas}
          onChange={(e) => setCapacidadePessoas(e.target.value)}
          label="Lotação Máx.: "
          required
        />
        <InputLabel
          type="text"
          value={PrazoAntecedencia}
          onChange={(e) => setPrazoAntecedencia(e.target.value)}
          label="Período máximo para reserva(em dias):"
          required
        />

        <Button type="submit">Salvar</Button>
      </Form>
    </Background>
  );
}
