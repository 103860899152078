import { useEffect, useState } from "react";
import { Background, Container, Button, Title, IconPerfil } from "./style";
import api from "../../services/api";
import { Swal, Toast } from "../../services/SwalTemplates";
import { userId } from "../../services/TokenData";
import InputLabel from "../../components/InputLabel";
import { useNavigate } from "react-router-dom";

export default function Perfil() {
  const [Nome, setNome] = useState("");
  const [Email, setEmail] = useState("");
  const [Perfil, setPerfil] = useState("");
  const [Senha, setSenha] = useState("");
  const [Reload, setReload] = useState(false);
  const [ConfirmarSenha, setConfirmarSenha] = useState("");
  const UserId = userId();
  const Navigate = useNavigate();

  useEffect(() => {
    api.get(`/user/usuario?id=${UserId}`).then((response) => {
      setNome(response?.data?.response?.nome);
      setEmail(response?.data?.response?.email);
      setPerfil(response?.data?.response?.perfil);
    });
  }, [UserId, Reload]);

  async function HandleSubmit(e) {
    e.preventDefault();

    if (Senha !== ConfirmarSenha) {
      setConfirmarSenha("");
      Swal.fire({
        title: "Atenção!",
        text: "As senhas devem ser iguais!",
        icon: "warning",
      });
      return;
    }

    const data = {
      id: UserId,
      nome: Nome,
      senha: Senha,
      email: Email,
      perfil: Perfil,
    };

    api.patch(`/user/usuario`, { data }).then((response) => {
      Toast.fire({ title: response?.data?.success, icon: "success" });
      if (Senha) {
        setTimeout(() => {
          localStorage.removeItem("token");
          Navigate("/");

          return;
        }, 1000);
      }

      setReload(!Reload);
    });
  }

  return (
    <Background>
      <Container className="myProfile">
      <Title style={{marginTop:"3%"}}>Meus Dados</Title>
      <IconPerfil/>
      <p><strong>Nome do usuário:</strong> {Nome}</p>
      <p><strong>Email do usuário: </strong>{Email}</p>
      <p style={{marginBottom:"3%"}}><strong>Tipo de perfil: </strong>{Perfil}</p>
      

      </Container>
      <Container onSubmit={HandleSubmit} className="changeProfile">
        <Title style={{marginTop:"2%"}}>Alterar Dados</Title>
        <InputLabel
          type="text"
          value={Nome}
          onChange={(e) => setNome(e.target.value)}
          label="Nome"
          required
        />
        <InputLabel
          type="email"
          value={Email}
          onChange={(e) => setEmail(e.target.value)}
          label="Email"
          disabled
          required
        />
        <InputLabel type="text" value={Perfil} label="Perfil" disabled />
        <InputLabel
          type="password"
          value={Senha}
          onChange={(e) => setSenha(e.target.value)}
          label="Senha"
          minLength={10}
        />
        <InputLabel
          type="password"
          value={ConfirmarSenha}
          onChange={(e) => setConfirmarSenha(e.target.value)}
          label="Confirmar Senha"
          minLength={10}
        />
        <Button type="submit">Salvar</Button>
      </Container>
    </Background>
  );
}
