import React, { useState, useEffect } from "react";
import { Background, Form, Title, Button, CloseIcon } from "./style";
import InputLabel from "../../../InputLabel";
import SelectLabel from "../../../SelectLabel";
import api from "../../../../services/api";
import { Swal, Toast } from "../../../../services/SwalTemplates";
import { difference, format } from "../../../../services/DateFormatter";
import { userId } from "../../../../services/TokenData"

export default function RegisterProcesso({ Show, setShow, setReload, Reload }) {
  const [Assunto, setAssunto] = useState("");
  const [DataEntrada, setDataEntrada] = useState(
    format({ DataInicial: new Date(), Formato: "YYYY-MM-DD" })
  );
  const [DataInicio, setDataInicio] = useState(
    format({ DataInicial: new Date(), Formato: "YYYY-MM-DD" })
  );
  const [DiasPrevistos, setDiasPrevistos] = useState(1);
  const [DiasAmarelo, setDiasAmarelo] = useState(1);
  const [DiasVermelho, setDiasVermelho] = useState(1);
  const [Responsaveis, setResponsaveis] = useState([]);
  const [IdResponsaveis, setIdResponsaveis] = useState([]);
  const [IdResponsavel, setIdResponsavel] = useState(0);
  const PorcentagemAmarelo = 0.2;
  const PorcentagemVermelho = 0.1;

  useEffect(() => {
    async function Request() {
      api.get(`/user/usuarios`).then((response) => {
        setResponsaveis(response.data.usuarios.map((r) => r.nome));
        setIdResponsaveis(response.data.usuarios.map((r) => r.id));
      });
    }
    Request();
  }, []);

  async function HandleSubmit(e) {
    e.preventDefault();

    const data = {
      assunto: Assunto,
      responsavel: IdResponsavel,
      situacao:
        difference({
          DataInicial_1: DataEntrada,
          DataInicial_2: DataInicio,
        }) > 0
          ? "Aguardando"
          : "Executando",
      dias_previstos: DiasPrevistos,
      data_entrada: DataEntrada,
      data_inicio: DataInicio,
      dias_para_amarelo: DiasAmarelo,
      dias_para_vermelho: DiasVermelho,
      id_gestor: userId(),
    };

    if (!data.responsavel) {
      Toast.fire({
        title: "Por favor, selecione um responsável",
        icon: "warning",
        timer: 2500,
      });
    } else {
      api
        .post(`/processos`, { data })
        .then((response) => {
          Toast.fire({
            title: response?.data?.success,
            icon: "success",
            timer: 2500,
          });
          api.post(`/user/mail_processo`, { data })
          Close();
          setReload(!Reload);
        })
        .catch((e) => {
          Close();
          console.log(e);
          Swal.fire({
            title: "Atenção!",
            text: "Error",
            icon: "error",
            timer: 2500,
          });
        });
    }
  }

  async function Close() {
    setShow(false);
    setAssunto("");
    setDataEntrada(format({ DataInicial: new Date(), Formato: "YYYY-MM-DD" }));
    setDataInicio(format({ DataInicial: new Date(), Formato: "YYYY-MM-DD" }));
    setDiasPrevistos(1);
    setDiasAmarelo(1);
    setDiasVermelho(1);
    setIdResponsavel(0);
  }

  return (
    <Background Show={Show}>
      <Form onSubmit={HandleSubmit}>
        <CloseIcon onClick={() => Close()} />
        <Title>Cadastrar Processo</Title>
        <InputLabel
          type="text"
          value={Assunto}
          onChange={(e) => setAssunto(e.target.value)}
          label="Assunto"
          required
        />
        <InputLabel
          type="date"
          value={DataEntrada}
          onChange={(e) => setDataEntrada(e.target.value)}
          label="Data Entrada"
          required
        />
        <SelectLabel
          label="Responsável"
          value={IdResponsavel}
          onChange={(e) => setIdResponsavel(e.target.value)}
          defaultValue="Responsável"
          options={Responsaveis}
          values={IdResponsaveis}
        />
        <InputLabel
          type="date"
          value={DataInicio}
          onChange={(e) => setDataInicio(e.target.value)}
          label="Data Inicio"
          required
        />
        <InputLabel
          type="number"
          value={DiasPrevistos}
          onChange={(e) => {
            setDiasPrevistos(e.target.value);
            setDiasAmarelo(
              Math.ceil(parseInt(e.target.value, 10) * PorcentagemAmarelo)
            );
            setDiasVermelho(
              Math.ceil(parseInt(e.target.value, 10) * PorcentagemVermelho)
            );
          }}
          label="Dias Previstos"
          required
        />
        <InputLabel
          type="number"
          value={DiasAmarelo}
          onChange={(e) => setDiasAmarelo(e.target.value)}
          label="Dias Alerta Amarelo"
          min={1}
          required
        />
        <InputLabel
          type="number"
          value={DiasVermelho}
          onFocus={(e) => e.target.select()}
          onChange={(e) => {
            setDiasVermelho(
              parseInt(e.target.value, 10) < DiasAmarelo &&
                parseInt(e.target.value, 10) > 0
                ? e.target.value
                : 1
            );
          }}
          label="Dias Alerta Vermelho"
          min={1}
          max={DiasAmarelo - 1}
          required
        />
        <Button type="submit">Salvar</Button>
      </Form>
    </Background>
  );
}
