import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { Container } from "./style";
import Cards from "../../components/Modal/Cards";
import { TokenPerfil } from "../../services/TokenData";

export default function Categoria({
  id_tipos,
  descricao,
  dataPretendida,
  horaInicio,
  horaFim,
  dados,
  AtualizaPag,
  setAtualizaPag,
  espacos,
  key,
  filtroAtivo,
  Reload,
  setReload,
}) {
  const [Locais, setLocais] = useState(espacos || []);
  const desabilitado = TokenPerfil() === "GESTOR" ? false : true;

  useEffect(() => {
    api.get(`/locais/${id_tipos}`).then((response) => {
      setLocais(response.data.locais);
    });
  }, [Reload, id_tipos, AtualizaPag]);

  useEffect(() => {
    setLocais(espacos);
  }, [espacos]);

  return (
    <Container>
      <div className="titulo">
        <h2 className={descricao}>{descricao}</h2>
      </div>

      <div className="conteudo">
        {Locais.map((x) =>
          x.id_tipo === id_tipos ? (
            <Cards
              key={x.id}
              chave={x.id_tipos}
              nome={x.descricao}
              tipo={descricao}
              lotacao={x.capacidade_pessoas}
              id_local={x.id}
              prazo_antecedencia={x.prazo_antecedencia_dias}
              filtroAtivo={filtroAtivo}
              dataPretendida={dataPretendida}
            />
          ) : undefined
        )}
        <Cards
          chave={"add"}
          nome="Adicionar"
          tipo={descricao}
          id_tipos={id_tipos}
          AtualizaPag={AtualizaPag}
          setAtualizaPag={setAtualizaPag}
          disabled={desabilitado}
        />
      </div>
    </Container>
  );
}
