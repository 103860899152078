import styled from "styled-components";
import {
  MdKeyboardArrowRight,
  MdKeyboardArrowLeft,
} from "react-icons/md";

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  color: var(--secondary-font-color);
  background-color: var(--table-primary-color);
  border-radius: 5px;
  margin-top: 20px;
  caption {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    color: var(--secondary-font-color);
    background-color: var(--table-primary-color);
    border-radius: 5px;
    font-size: 25px;
  }

  td,
  th {
    padding: 10px;
  }
  td {
  }
  th {
    height: 30px;
  }
  tbody {
    td:hover {
      background-color: var(--table-secondary-color);
    }
  }

  tr {
    border: 1px solid var(--table-border-color);
  }

  
`;
export const Calendario = styled.div `
  width:100%;
  text-align: center;
  color: var(--secondary-font-color);
  background-color: var(--secondary-bg-color);
  border-radius: 10px;
  margin-top: 5px;
  
  .calendarioMensal{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    justify-items:center;
    box-sizing:border-box;
    p{
      width: 100%;
      height:100px;
      border:1px solid var(--secondary-font-color);
      padding:40px;
      font-size:20px;
      font-weight:700;
      color:var(--secondary-font-color);
      border-radius:10px;
      background-color:var(--table-primary-color);
    }
    p:hover{
      cursor:pointer;
      border: 3px solid black;
      color:var(--secondary-font-color);
    }
    .styleHoje{
      background-color:var(--secondary-font-color); 
      width:100%;
      color:black;
      border: 4px solid black;
    }
    .styleHoje:hover{
      background-color:var(--secondary-font-color); 
      width:100%;
      color:black;
      border: 4px solid black;
      scale: 1.03;
      transition: scale 1s;
    }
  }
`;


export const PreviousOne = styled(MdKeyboardArrowLeft)`
  font-size: 25px;
  font-weight: 800;
  cursor: pointer;
  margin-right:20px;
  &:hover {
    background-color: var(--main-color);
  }
`;

export const NextOne = styled(MdKeyboardArrowRight)`
  font-size: 25px;
  font-weight: 800;
  cursor: pointer;
  margin-left:20px;
  &:hover {
    background-color: var(--main-color);
  }
`;
