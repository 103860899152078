import React, { useState } from "react";
import { Container, Add } from "./style";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import AddRoom from "../Gestor/AddRoom";
import { TokenPerfil } from "../../../services/TokenData";
const Cards = ({
  nome,
  tipo,
  lotacao,
  chave,
  id_tipos,
  id_local,
  prazo_antecedencia,
  AtualizaPag,
  setAtualizaPag,
  filtroAtivo,
  dataPretendida,
  disabled,
}) => {
  let opc;
  nome === "" ? (opc = "nd") : (opc = "oi");
  const Navigate = useNavigate();
  const [ShowModalAddRoom, setShowModalAddRoom] = useState(false);

  return (
    <>
      {chave !== "add" ? (
        <Container
          title={nome}
          onClick={() =>
            Navigate(
              TokenPerfil() === "GESTOR"
                ? "/gestor/reservar"
                : "/operador/reservar",
              {
                state: {
                  name: nome,
                  tipo: tipo,
                  id_tipos: id_tipos,
                  idLocal: id_local,
                  idTipo: id_tipos,
                  lotacao: lotacao,
                  prazo_antecedencia: prazo_antecedencia,
                  filtroAtivo: filtroAtivo,
                  dataPretendida: dataPretendida,
                },
              }
            )
          }
        >
          <p className={opc}>
            <strong>{String(nome)}</strong>
          </p>
          {/* <p className={opc}>
            <strong>Tipo:</strong> {tipo}
          </p> */}
          <p className={opc}>Lotação Max.: {lotacao}</p>
          <p className={opc}>Prazo máx. (dias): {prazo_antecedencia}</p>
        </Container>
      ) : (
        <Container
          disabled={disabled}
          onClick={() => setShowModalAddRoom(true)}
        >
          <div className="adicionar">
            <Add></Add>
            <p>
              {nome} {tipo}
            </p>
          </div>
        </Container>
      )}

      <AddRoom
        Show={ShowModalAddRoom}
        setShow={setShowModalAddRoom}
        tipo={tipo}
        idTipo={id_tipos}
        AtualizaPag={AtualizaPag}
        setAtualizaPag={setAtualizaPag}
      />
    </>
  );
};
Cards.propTypes = {
  nome: PropTypes.string.isRequired,
  tipo: PropTypes.string.isRequired,
};
export default Cards;
