import {
  Background,
  Title,
  Button,
  Form,
  Clock,
  PersonAdd,
  Pencil,
  CloseIcon,
} from "./style";
import InputLabel from "../../../components/InputLabel";
import SelectLabel from "../../../components/SelectLabel";
import React, { useState, useEffect } from "react";
import { format } from "../../../services/DateFormatter";
import api from "../../../services/api";
import { userId } from "../../../services/TokenData";
import { Swal } from "../../../services/SwalTemplates";
import FileInput from "../../FileInput";
import Observação from "../Observacao_Agendar";

export default function EditarAgendamento({ Show, setShow, Dados, Reload, setReload, Path }) {
  const UserId = userId();
  const [Titulo, setTitulo] = useState("");
  const [Data, setData] = useState("");
  const [Destinatarios, setDestinatarios] = useState(null);
  const [IdDestinatarios, setIdDestinatarios] = useState([]);
  const diasDaSemana = [
    "Domingo",
    "Segunda-feira",
    "Terça-feira",
    "Quarta-feira",
    "Quinta-feira",
    "Sexta-feira",
    "Sábado",
  ];
  const [isChecked, setIsChecked] = useState(false);
  const [Selecionados, setSelecionados] = useState([]);
  const [DadosParticipantes, setDadosParticipantes] = useState([]);
  const [ShowFileInput, setShowFileInput] = useState(false);
  const [Anexo, setAnexo] = useState(null);
  const [ShowModalObservacao, setShowModalObservacao] = useState(false);
  const [Observacao, setObservacao] = useState("");
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    async function Request() {
      api.get(`/user/destinatarios/${userId()}`).then((response) => {
        setDestinatarios([
          "Ninguém",
          ...response.data.usuarios.map((r) => r.nome),
        ]);
        setDadosParticipantes([...response.data.usuarios]);
      });
    }
    Request();
    setData(Dados?.data);
    setTitulo(Dados?.titulo);
    setObservacao(Dados?.observacao);
  }, [Show, Dados]);
  
  async function HandleSubmit(e) {
    e.preventDefault();
    const diaDaSemana = new Date(
      format({ DataInicial: Data, Formato: `YYYY-MM-DD` })
    );

    const data = {
      id: Dados?.id,
      titulo: Titulo,
      observacao: Observacao || null,
      dia_semana: diasDaSemana[diaDaSemana.getDay() + 1] || "Dom",
      id_usuario_acao: UserId
    };

    if(Path === "MyReserve"){
      
      api
      .patch(`/agendamentos/${Dados?.titulo}/${format({DataInicial: Dados?.dia, Formato: "YYYY-MM-DD"})}`, { data })
      .then((response) => {
        let i = 0;

        while (i < IdDestinatarios.length) {
          api
            .post(`/agendamentos_participantes`, {
              id_agendamento: Dados.id,
              id_usuario: IdDestinatarios[i],
            })
            .then((response) => {})
            .catch((e) => {
              console.log(e);
            });

          i++;
        }

        if (!!Anexo) {
          const config = {
            headers: { "content-type": "multipart/form-data" },
          };
          Anexo.set("id_agendamento", data.id);

          api
            .post(`/anexos`, Anexo, config)
            .then((resAnexo) => {
              Swal.fire({
                title: "Sucesso!",
                text: response?.data?.success,
                icon: "success",
                confirmButtonColor: "var(--main-color)",
              });
            })
            .catch((e) => {
              Swal.fire({ title: "Atenção!", text: e, icon: "error" });
            });
        } else {
          Swal.fire({
            title: "Sucesso!",
            text: response?.data?.success,
            icon: "success",
            confirmButtonColor: "var(--main-color)",
          });
          setReload(!Reload);
        }
      })
      .catch((e) => {
        Swal.fire({ title: "Atenção!", text: e, icon: "error" });
      });
    Close();
    }
    else{
      api
      .patch(`/agendamentos/${data.id}`, { data })
      .then((response) => {
        let i = 0;

        while (i < IdDestinatarios.length) {
          api
            .post(`/agendamentos_participantes`, {
              id_agendamento: Dados.id,
              id_usuario: IdDestinatarios[i],
            })
            .then((response) => {})
            .catch((e) => {
              console.log(e);
            });

          i++;
        }

        if (!!Anexo) {
          const config = {
            headers: { "content-type": "multipart/form-data" },
          };
          Anexo.set("id_agendamento", data.id);

          api
            .post(`/anexos`, Anexo, config)
            .then((resAnexo) => {
              Swal.fire({
                title: "Sucesso!",
                text: response?.data?.success,
                icon: "success",
                confirmButtonColor: "var(--main-color)",
              });
            })
            .catch((e) => {
              Swal.fire({ title: "Atenção!", text: e, icon: "error" });
            });
        } else {
          Swal.fire({
            title: "Sucesso!",
            text: response?.data?.success,
            icon: "success",
            confirmButtonColor: "var(--main-color)",
          });
          setReload(!Reload);
        }
      })
      .catch((e) => {
        Swal.fire({ title: "Atenção!", text: e, icon: "error" });
      });
    Close();
    }
    
  }

  async function Close() {
    setShow(false);
    setTitulo("");
    setSelecionados([]);
    setIsChecked(false);
    setIdDestinatarios([]);
    setObservacao("");
  }

  const aoSelecionarPart = (e) => {
    const partSelecionado = e.target.value;

    for (let dado of DadosParticipantes) {
      if (dado.nome === partSelecionado && !IdDestinatarios.includes(dado.id)) {
        setIdDestinatarios([dado.id, ...IdDestinatarios]);
      }
    }

    setSelecionados((prevEnviarPara) => {
      if (!prevEnviarPara.includes(partSelecionado)) {
        return [partSelecionado, ...prevEnviarPara];
      }
      return prevEnviarPara;
    });
  };

  function removeUserParti(user) {
    const userObj = DadosParticipantes.find((e) => e.nome === user);
    if (!userObj) return;

    const id = userObj.id;
    const name = userObj.nome;

    const indexId = IdDestinatarios.indexOf(id);
    if (indexId === -1) return;

    const newEnviarPara = Selecionados.filter((item) => item !== name);
    const newIdDestinatario = IdDestinatarios.filter((item) => item !== id);
    setIdDestinatarios(newIdDestinatario);
    setSelecionados(newEnviarPara);
  }

  return (
    <>
      <Background Show={Show}>
        <Form onSubmit={HandleSubmit}>
          <CloseIcon onClick={() => Close()} />

          <Title>
            Agendamento{" "}
            {format({ DataInicial: Dados?.dia, Formato: "DD/MM/YYYY" })}
          </Title>

          <div className="titulo">
            <Pencil />
            <InputLabel
              value={Titulo}
              onChange={(e) => setTitulo(e.target.value)}
              type="text"
              label="Título"
              required
              maxLength={50}
            />
          </div>

          <div className="horarios">
            <Clock />
            <div className="horario-input">
              <InputLabel
                label="Início"
                value={Dados?.hora_inicio?.slice(0, 5) || Dados?.hora_inicial?.slice(0, 5)}
                required
                disabled
              />
              <InputLabel
                label="Fim"
                value={Dados?.hora_fim?.slice(0, 5) || Dados?.hora_final?.slice(0, 5)}
                required
                disabled
              />
            </div>
          </div>

          <div className="check-add">
            <input
              type="checkbox"
              className="check-adicionar"
              checked={isChecked}
              onChange={handleOnChange}
            />
            <label style={{ color: 'var(--primary-font-color)' }}>Adicionar participantes?</label>
          </div>

          <div className="participantes">
            <PersonAdd />
            <SelectLabel
              value={IdDestinatarios}
              onChange={aoSelecionarPart}
              defaultValue="Selecione o usuário"
              options={Destinatarios}
              values={Destinatarios}
              disabled={!isChecked}
            />
          </div>
          <div
            style={{
              display: Selecionados.length === 0 ? "none" : "flex",
              width: "80%",
            }}
            className="listaParticipantes"
          >
            <ul
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "10px",
                justifyItems: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              {Selecionados.map((e) => (
                <li onClick={() => removeUserParti(e)}>{e.slice(0, 10)}</li>
              ))}
            </ul>
          </div>
          <div
            style={{
              display: "flex",
              gap: "7%",
              justifyContent: "center",
              marginBottom: "2%",
            }}
          >
            <Button
              type="button"
              style={{ backgroundColor: "grey" }}
              onClick={() => setShowFileInput(true)}
            >
              Anexo
            </Button>
            <Button
              type="button"
              style={{ backgroundColor: "grey" }}
              onClick={() => setShowModalObservacao(!ShowModalObservacao)}
            >
              Observação
            </Button>
            <Button type="submit">Salvar</Button>
          </div>
        </Form>
        <FileInput
          setShow={setShowFileInput}
          Show={ShowFileInput}
          Anexo={Anexo}
          setAnexo={setAnexo}
          Origin={"Agendamento"}
        />
      </Background>
      <Observação
        ShowModalObservacao={ShowModalObservacao}
        setShowModalObservacao={setShowModalObservacao}
        Observacao={Observacao}
        setObservacao={setObservacao}
      />
    </>
  );
}
