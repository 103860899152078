import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import {
  Background,
  Container,
  Title,
  Filters,
  Button,
  Search,
  CloseSearch,
} from "./style";
import Categoria from "../../../components/Categoria";
// import GoBack from "../../../components/GoBack";
import InputLabel from "../../../components/InputLabel";
import SelectLabel from "../../../components/SelectLabel/index";
import Texts from "../../../assets/json/texts.json";
import { format } from "../../../services/DateFormatter";
import { useNavigate } from "react-router-dom";


export default function Rooms() {
  const [TiposEspacos, setTiposEspacos] = useState([]);
  const [Reload, setReload] = useState(false);
  const [Pesquisar, setPesquisar] = useState(false);
  const [BuscaAvancada, setBuscaAvancada] = useState(false);
  const [SearchFiltro, setSearchFiltro] = useState("");
  const [HoraInicio, setHoraInicio] = useState(
    Texts.STATIC_OPC_HORARIOS_INICIO[0]
  );
  const [HoraFim, setHoraFim] = useState(Texts.STATIC_OPC_HORARIOS_FIM[0]);
  const [TipoFiltroAvan, setTipoFiltroAvan] = useState({
    id: 0,
    descricao: "Todos",
  });
  const [DataPretendida, setDataPretendida] = useState("00-00-0000");
  // eslint-disable-next-line no-unused-vars
  const [Dados, setDados] = useState(null);
  const [AtualizaPag, setAtualizaPag] = useState(false);
  const [Espacos, setEspacos] = useState([]);
  const Navigate = useNavigate();
  const btn = BuscaAvancada ? "Busca Simples" : "Busca Avançada" ;
  
  useEffect(() => {
    api.get(`/tipos_local`).then((response) => {
      const tiposLocal = response.data.tiposLocal;
      setTiposEspacos(tiposLocal);
    });

    api
      .get(
        `/locais/search_name/${!SearchFiltro ? "busca vazia" : SearchFiltro}`
      )
      .then((response) => {
        setEspacos(response.data.locais);
      });
  }, [Reload, SearchFiltro]);

  async function HandleSubmit(e) {
    e.preventDefault();
    const data = {
      id_tipo: TipoFiltroAvan.id,
      data_pretendida: DataPretendida,
      horario_inicio: HoraInicio,
      horario_fim: HoraFim,
    };

    api.post(`/locais/search`, { data }).then((response) => {
      setEspacos(response.data.locais);
    });
  }

  function getIdSpaceType(value) {
    if (value === "Todos") {
      return { id: 0, descricao: "Todos" };
    }
    return (
      TiposEspacos.find((e) => e.descricao === value) || {
        id: 0,
        descricao: "Todos",
      }
    );
  }

  return (
    <Background>
      <Container Pesquisar={Pesquisar} BuscaAvancada={BuscaAvancada}>
        {/* <GoBack /> */}
        <div className="header-reserva">
          <Title Pesquisar={Pesquisar} BuscaAvancada={BuscaAvancada}>Controle de Reservas</Title>
          <Filters Pesquisar={Pesquisar} BuscaAvancada={BuscaAvancada}>
            <Button
              className="btn-busca-avancada"
              onClick={() => {
                setBuscaAvancada(!BuscaAvancada);
                setPesquisar(!Pesquisar);
              }}
            >
              <p>{btn}</p>
            </Button>
            <div className="search-container">
              <InputLabel
                type="search"
                label="Buscar"
                value={SearchFiltro}
                onChange={(e) => setSearchFiltro(e.target.value)}
              />
            </div>
            <Button
              className="btn-busca-simples"
              onClick={() =>
                setPesquisar(BuscaAvancada === true ? Pesquisar : !Pesquisar)
              }
            >
              {Pesquisar === true ? <CloseSearch /> : <Search />}
            </Button>

            <div className="filtros-avancados">
              <div className="filtro-tipo-espaco">
                <SelectLabel
                  label="Tipo"
                  options={Texts.STATIC_OPC_CATEGORIAS}
                  values={Texts.STATIC_OPC_CATEGORIAS}
                  onChange={(e) => {
                    setTipoFiltroAvan(getIdSpaceType(e.target.value));
                  }}
                />
              </div>
              <div className="filtro-data-pretendida">
                <InputLabel
                  value={DataPretendida}
                  type="date"
                  label="Data Pretendida"
                  onChange={(e) => setDataPretendida(e.target.value)}
                  min={format({ DataInicial: new Date(), Formato: "YYYY-MM-DD" })}
                />
              </div>
              <div className="filtro-hora-inicial">
                <SelectLabel
                  label="Hora Inicial"
                  values={Texts?.STATIC_OPC_HORARIOS_INICIO}
                  options={Texts?.STATIC_OPC_HORARIOS_INICIO.map((e) =>
                    e.slice(0, 5)
                  )}
                  onChange={(e) => setHoraInicio(e.target.value)}
                  disabled={
                    DataPretendida === "00-00-0000" || DataPretendida === ""
                      ? true
                      : false
                  }
                />
              </div>
              <div className="filtro-hora-final">
                <SelectLabel
                  label="Hora Final"
                  values={Texts?.STATIC_OPC_HORARIOS_FIM}
                  options={Texts?.STATIC_OPC_HORARIOS_FIM.map((e) =>
                    e.slice(0, 5)
                  )}
                  onChange={(e) => setHoraFim(e.target.value)}
                  disabled={
                    DataPretendida === "00-00-0000" || DataPretendida === ""
                      ? true
                      : false
                  }
                />
              </div>
              <Button
                className="btn-busca-filtros"
                type="submit"
                onClick={HandleSubmit}
              >
                Filtrar
              </Button>
            </div>
          </Filters>
        </div>
        <div className="minhasReservas">
          <Button onClick={() => 
            Navigate("/minhas-reservas")
          }>Minhas Reservas</Button>
        </div>
        <main>
          {TiposEspacos.map((x) => (
            <Categoria
              key={x.id}
              descricao={x.descricao}
              id_tipos={x.id}
              dataPretendida={DataPretendida}
              horaInicio={HoraInicio}
              horaFim={HoraFim}
              dados={Dados}
              AtualizaPag={AtualizaPag}
              setAtualizaPag={setAtualizaPag}
              espacos={Espacos}
              filtroAtivo={TipoFiltroAvan.id !== 0 ? true : false}
              Reload={Reload}
              setReload={setReload}
            />
          ))}
        </main>
      </Container>
    </Background>
  );
}
