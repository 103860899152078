import styled from "styled-components";
import { FaSearch } from "react-icons/fa";
import { FaWindowClose } from "react-icons/fa";

export const Title = styled.h1`
  margin-bottom: 20px;
  font-size: 40px;
  width:fit-content;
  min-width:30%;
  text-align:${(props) => props.BuscaAvancada ? "center" : "end"};
  padding-top:15px;

  @media (max-width: 1615px) {
    min-width:${(props) => props.Pesquisar ? "50%" : props.BuscaAvancada ? "80%" : "15%"};
    text-align: ${(props) => props.Pesquisar || props.BuscaAvancada ? "center" : "end"};
  }
  
  @media (max-width: 1300px) {
    min-width:${(props) => props.Pesquisar ? "40%" : props.BuscaAvancada ? "80%" : "15%"};
    text-align: ${(props) => props.Pesquisar ? "start" : props.BuscaAvancada ? "center" : "end"};
  }

  @media (max-width: 635px) {
    font-size: 40px;
  }
  @media (max-width: 656px) {
    text-align:${(props) => props.Pesquisar ? "end":"center"};
    min-width:80%;
  }
  @media (max-width: 554px) {
    font-size:40px;

    min-width:80%;
  }
  @media (max-width: 521px) {
    min-width:85%;
    margin-left:25px;
  }
  @media (max-width: 493px) {
    min-width:100%;
    text-align:center;
    margin-left:0px;
    font-size:40px;
  }
`;

export const Background = styled.div`
  min-width: 99vw;
  width: 100%;
  min-height: calc(100vh - 60px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 50px;
  position: relative;

  @media (max-width: 1440px) {
    padding-top: 100px;
    padding-bottom: 0px;
}
`;

export const Container = styled.div`
  height: fit-content;
  width: 95%;
  background-color: var(--secondary-bg-color);
  border-radius: 10px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  box-sizing: border-box;

  .header-reserva{
    display:flex;
    flex-direction:${(props) => props.BuscaAvancada ? "column" : "row"};
    width:100%;
    justify-content: center;
    align-items: center; 
    gap: ${(props) => props.Pesquisar ? "20px" : "0px"};
  }

  .minhasReservas{
    width:90%;
    margin-top:${(props) => props.BuscaAvancada || props.Pesquisar ? "15px" : "0px"};
    display:flex;
    flex-direction:row;
    justify-content:end;
    
    Button{
      width:fit-content;
      padding:5px 10px;
      padding-bottom:25px;
      height:25px;
      background-color:var(--table-primary-color);
    }
  }

  main {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    justify-content:center;
  }

  @media (max-width: 1440px) {
    padding: 15px;
  }

  @media (max-width: 1210px) {
    .header-reserva{
      flex-direction: ${(props) => props.Pesquisar || props.BuscaAvancada ? "column" : "row"};
    }  
  }
  @media (max-width: 955px) {
    .header-reserva{
      gap:${(props) => props.BuscaAvancada ? "170px" : "0px"};
      margin-bottom:${(props) => props.BuscaAvancada ? "12%" : "0%"};
    }
    .minhasReservas{
      margin-top:4%;
      justify-content:center;
    }
  }

  @media (max-width: 855px) {
    gap:${(props) => props.BuscaAvancada ? "70px" : "0px"};
    main {
      display: grid;
      grid-template-columns: 100%; 
    }
  }

  @media (max-width: 799px) {
    .header-reserva{
      gap:${(props) => props.Pesquisar ? "70px" : props.BuscaAvancada ? "170px" : "0px"};
    }
  }
  
  @media (max-width: 493px) {
    .header-reserva{
      flex-direction:column;
      gap:${(props) => props.Pesquisar ? "50px" : props.BuscaAvancada ? "180px" : "0px"};
    }  
    .minhasReservas{
      margin-top:6%;
    }
  }
`;
export const Filters = styled.div`
  width:${(props) => props.Pesquisar ? "50%" : props.BuscaAvancada ? "80%" : "10%"};
  display: grid;
  grid-template-columns: ${(props) =>
    props.Pesquisar
      ? "15% 70% 15% 0%"
      : props.BuscaAvancada
      ? "15% 0% 0% 85%"
      : "0% 0% 50% 0%"};
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 5px;
  max-height: 50px;

  .btn-busca-avancada {
    width: 100%;
    background-color: var(--filter-color);
    color: var(--filter-text-color);
    align-self: center;
    justify-content: center;
    align-items: center;
    padding: 2px 5px;
    p {
      width: 100%;
      font-size: 12px;
      text-transform: none;
    }
  }

  .btn-busca-simples {
    height: 35px;
    font-size: 16px;
    background-color: var(--secondary-bg-color);
    color: black;
    text-transform: none;
    font-weight: 600;
    align-self: center;
    padding: 10px 15px 30px 15px;
    display: flex;
    justify-content: center;
  }

  .btn-busca-avancada {
    transform: ${(props) =>
      props.Pesquisar || props.BuscaAvancada ? "scale(1)" : "scale(0)"};
    transition: ${(props) =>
      props.Pesquisar || props.BuscaAvancada
        ? "transform 1s ease"
        : "transform 0s linear"};
  }

  .search-container {
    transform: ${(props) => (props.Pesquisar ? "scale(1)" : "scale(0)")};
    transition: ${(props) =>
      props.Pesquisar ? "transform 1s ease" : "transform 0s linear"};
  }

  .filtros-avancados {
    transform: ${(props) => (props.BuscaAvancada ? "scale(1)" : "scale(0)")};
    transition: ${(props) =>
      props.BuscaAvancada ? "transform 1s ease" : "transform 0s linear"};
    display: ${(props) => (props.BuscaAvancada ? "flex" : "")};
    flex-direction: row;
    width: 100%;

    .filtro-tipo-espaco {
      display: inline-block;
      width: 20%;
    }

    .filtro-data-pretendida {
      width: 20%;
      display: inline-block;
      margin-left: 10px;
    }

    .filtro-hora-inicial {
      width: 17%;
      display: inline-block;
      margin-left: 10px;
    }

    .filtro-hora-final {
      width: 17%;
      display: inline-block;
      margin-left: 10px;
    }

    .btn-busca-filtros {
      height: 35px;
      font-size: 16px;
      background-color: var(--filter-color);
      color: var(--filter-text-color);
      text-transform: none;
      font-weight: 600;
      align-self: center;
      padding: 8px 15px 20px 15px;
      display: inline-block;
      justify-content: center;
      margin-left: 10px;
      margin-bottom:5px;
    }

    .btn-busca-filtros:hover {
      transform: scale(1.05);
      box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
      background-color: black;
      color: white;
      margin-left: 10px;
    }
  }

  @media (max-width: 1615px) {
    width:${(props) => props.Pesquisar ? "70%" : props.BuscaAvancada ? "80%" : "15%"};
    justify-content: ${(props) => props.Pesquisar ? "start" : "start"};
  }

  @media (max-width: 1300px) {
    width:${(props) => props.Pesquisar ? "70%" : props.BuscaAvancada ? "80%" : "15%"};
    justify-content: ${(props) => props.Pesquisar ? "center" : "start"};
  }

  @media (max-width: 1210px) {
    width:${(props) => props.Pesquisar ? "100%" : props.BuscaAvancada ? "80%" : "15%"};
    justify-content: ${(props) => props.Pesquisar ? "center" : "start"};
    grid-template-columns: ${(props) =>
    props.Pesquisar
      ? "10% 63% 7% 0%"
      : props.BuscaAvancada
      ? "15% 0% 0% 85%"
      : "0% 0% 50% 0%"};
  }
  
  @media (max-width: 955px) {
      width:${(props) => props.Pesquisar ? "80%" : props.BuscaAvancada ? "80%" : "20%"};
      display: grid;
      grid-template-columns: ${(props) =>
        props.Pesquisar
          ? "15% 70% 15% 0%"
          : props.BuscaAvancada
          ? "15% 0% 0% 85%"
          : "0% 0% 50% 0%"};
      align-items: center;
      align-content: center;
      justify-content: center;
      gap:${(props) => props.BuscaAvancada ? "10px" : "5px"};
      max-height: 50px;
      
      .filtros-avancados{
          display: ${(props) => props.BuscaAvancada ? "flex" : "block"};
          flex-direction:${(props) => props.BuscaAvancada ? "column" : "row"};
          grid-column-start:${(props) => props.BuscaAvancada ? "1" : "0"};
          grid-column-end:${(props) => props.BuscaAvancada ? "5 " : "0"};
          grid-row-start: ${(props) => props.BuscaAvancada ? "2" : "0"};
          grid-row-end: ${(props) => props.BuscaAvancada ? "3" : "0"};
          gap:${(props) =>props.BuscaAvancada ? "10px" : "5px"};
          .filtro-tipo-espaco{
            width:${(props) => props.BuscaAvancada ? "50%" : "0%"};
            margin-left:${(props) => props.BuscaAvancada ? "1%" : "0%"};
            justify-self: center;
            align-self: center; 
          }
          .filtro-data-pretendida{
           width:${(props) => props.BuscaAvancada ? "50%" : "0%"};
           justify-self: center;
          align-self: center; 
          }
          .filtro-hora-inicial{
            width:${(props) => props.BuscaAvancada ? "50%" : "0%"};
            justify-self: center;
            align-self: center; 
          }
          .filtro-hora-final{
            width:${(props) => props.BuscaAvancada ? "50%" : "0%"};
            justify-self: center;
            align-self: center; 
          }
          .btn-busca-filtros{
            width:${(props) => props.BuscaAvancada ? "50%" : "0%"};
            justify-self: center;
            align-self: center; 
          }
      }
      .btn-busca-avancada{
        grid-column-start:${(props) => props.BuscaAvancada ? "1" : "0"};
        grid-column-end:${(props) => props.BuscaAvancada ? "5 " : "0"};
        grid-row-start: ${(props) => props.BuscaAvancada ? "1" : "0"};
        grid-row-end: ${(props) => props.BuscaAvancada ? "2" : "0"};
        width:${(props) =>props.BuscaAvancada ? "50%" : "100%"};
        justify-self: center;
        align-self: center; 
        margin-left:${(props) => props.BuscaAvancada ? "10px" : "0px"};
      }
  }
  
  @media (max-width: 799px) {
    width:${(props) => props.Pesquisar ? "100%" : props.BuscaAvancada ? "100%" : "18%"};
    grid-template-columns: ${(props) =>
    props.Pesquisar
      ? "1fr 1fr 1fr 1fr"
      : props.BuscaAvancada
      ? "15% 0% 0% 85%"
      : "0% 0% 50% 0%"};
      
      .search-container{
        grid-column-start:${(props) => props.Pesquisar ? "1" : "0"};
        grid-column-end:${(props) => props.Pesquisar ? "5 " : "0"};
        grid-row-start: ${(props) => props.Pesquisar ? "1" : "0"};
        grid-row-end: ${(props) => props.Pesquisar ? "2" : "0"};
        width:80%;
        justify-self: center;
        align-self: center; 
      }
      .btn-busca-avancada{
        grid-column-start:${(props) => props.Pesquisar ? "2" : props.BuscaAvancada? "1" : "0"};
        grid-column-end:${(props) => props.Pesquisar ? "5" :props.BuscaAvancada? "5" : "0"};
        grid-row-start: ${(props) => props.Pesquisar ? "2" : props.BuscaAvancada? "1" : "0"};
        grid-row-end: ${(props) => props.Pesquisar ? "3" : props.BuscaAvancada? "2" : "0"};
        margin-left:${(props) => props.BuscaAvancada ? "10px" : "0px"};;
        width:${(props) =>props.BuscaAvancada ? "50%" : "50%"};
        justify-self: center;
        align-self: center;
        max-height:${(props) => props.BuscaAvancada ? "40px" : ""};
      }
      .btn-busca-simples{
        grid-column-start:${(props) => props.Pesquisar ? "1" : "0"};
        grid-column-end:${(props) => props.Pesquisar ? "3" : "0"};
        grid-row-start: ${(props) => props.Pesquisar ? "2" : "0"};
        grid-row-end: ${(props) => props.Pesquisar ? "3" : "0"};
        justify-self: ${(props) => props.Pesquisar ? "center" : "center"};
        align-self: ${(props) => props.Pesquisar ? "center" : "center"};
        width:${(props) => props.Pesquisar ? "30%" : "100%"}; 
      }
  }

  @media (max-width: 720px) {
    width:${(props) => props.Pesquisar ? "100%" : props.BuscaAvancada ? "80%" : "25% "};
    justify-content: center;
  }
  @media (max-width: 554px) {
    width:${(props) => props.Pesquisar ? "80%" : props.BuscaAvancada ? "80%" : "30% "};
    justify-content: center;

    .btn-busca-avancada{
      width:${(props) => props.BuscaAvancada ? "50%" : "70%"};
      justify-self: ${(props) => props.BuscaAvancada ? "center" : "start"};
      align-self: ${(props) => props.BuscaAvancada ? "center" : "start"};
    }
  }
  @media (max-width: 500px) {
    width:${(props) => props.Pesquisar ? "80%" : props.BuscaAvancada ? "80%" : "30% "};
    justify-content: center;
  }
  @media (max-width: 493px) {
    width:${(props) => props.Pesquisar ? "80%" : props.BuscaAvancada ? "80%" : "60% "};

    .filtros-avancados{
      gap:${(props) =>props.BuscaAvancada ? "15px" : "5px"};

      .filtro-tipo-espaco{
        width:${(props) => props.BuscaAvancada ? "80%" : "0%"};
        margin-left:${(props) => props.BuscaAvancada ? "1%" : "0%"}; 
      }
      .filtro-data-pretendida{
        width:${(props) => props.BuscaAvancada ? "80%" : "0%"};  
      }
      .filtro-hora-inicial{
        width:${(props) => props.BuscaAvancada ? "80%" : "0%"};   
      }
      .filtro-hora-final{
        width:${(props) => props.BuscaAvancada ? "80%" : "0%"};  
      }
      .btn-busca-filtros{
        width:${(props) => props.BuscaAvancada ? "80%" : "0%"};  
      }
    }
    .btn-busca-avancada{
      width:75%;
    }
  }
`;

export const Button = styled.button`
  display: flex;
  border: none;
  align-self: flex-start;
  height: 40px;
  border-radius: 5px;
  padding: 10px 40px;
  background-color: var(--main-color);
  color: var(--button-text-color);
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 800;
  transition: transform 0.5s;
  transition: box-shadow 0.5s;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  }

  &.btn-busca-simples:hover {
    margin-left: 5px;
    margin-bottom:5px;
  }
  @media (max-width: 798px) {
    margin-left: 8px;
    margin-bottom:8px;
  }
    @media (max-width: 768px) {
    height:50px;
  }
`;
export const Search = styled(FaSearch)`
  cursor: pointer;
  font-size: 25px;
  display: flex;
  justify-content: start;
  align-items: center;
  color:var(--primary-font-color);
`;

export const CloseSearch = styled(FaWindowClose)`
  cursor: pointer;
  font-size: 25px;
  display: flex;
  justify-content: start;
  align-items: center;
  color:var(--primary-font-color)
`;