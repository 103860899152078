import React, { useEffect, useState } from "react";
import {
  Background, Container, ScrollTable, Table, MoreInformation, LessInformation,
  RemoveIcon, EditIcon, ShowIcon, BackIcon
} from "./style";
import { userId } from "../../../services/TokenData";
import GoBack from "../../../components/GoBack/index.js"
import api from "../../../services/api.js";
import { format } from "../../../services/DateFormatter.js";
import { Swal } from "../../../services/SwalTemplates.js";
import Pagination from "../../../components/Pagination/index.js";
// import { useNavigate } from "react-router-dom";
import EditarAgendamento from "../../../components/Modal/EditAgendamento/index.js";
import ModalExibirAgendamento from "../../../components/Modal/ExibirAgendamento/index.js";

const MyReserve = () => {
  const UserId = userId();
  const [MinhasReservas, setMinhasReservas] = useState([]);
  const [EspacoSelecionado, setEspacoSelecionado] = useState(0);
  const [NomeEspacoSelecionado, setNomeEspacoSelecionado] = useState("");
  const [DadosAgendamentos, setDadosAgendamentos] = useState([]);
  const [ShowEditarAgendamento, setShowEditarAgendamento] = useState(false);
  const [ShowModalExibirAgendamento, setShowModalExibirAgendamento] = useState(false);
  const [AgendamentoSelecionado, setAgendamentoSelecionado] = useState();
  const [Path, setPath] = useState("");
  const [Reload, setReload] = useState(false);
  const [Page, setPage] = useState(0);
  const Limit = 15;
  // const Navigate = useNavigate();
  
  useEffect(() => {
    api
      .get(`/minhas-reservas/${UserId}`)
      .then((response) => {
        setMinhasReservas(response?.data?.reservas);
      });
    if (EspacoSelecionado !== 0) {
      api
        .get(`/minhas-reservas/${UserId}/${EspacoSelecionado}/${Number(Limit)}/${Number(Page)}`)
        .then((response) => {
          setDadosAgendamentos(response?.data?.agendamentos);
        });
    }
  }, [UserId, EspacoSelecionado, Reload, Page]);

  function deletaAgendamento(titulo, id_local, dia) {
    const diaASerDeletado = format({ DataInicial: dia, Formato: "YYYY-MM-DD" });
    Swal.fire({
      title: `Deseja excluir o agendamento?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--deep-carmine-pink)",
      cancelButtonColor: "gray",
      confirmButtonText: "DELETAR",
      cancelButtonText: "CANCELAR",
    }).then((result) => {
      if (result.isConfirmed) {
        api.delete(`/agendamentos/${titulo}/${id_local}/${diaASerDeletado}`).then((res) => {
          Swal.fire(`Agendamento deletado.`, "", "success");
          setReload(!Reload);
        });
      }

    });
  }

  return (
    <>
      <Background>
        <div style={{ display: EspacoSelecionado ? "none" : 'flex' }}>
          <GoBack />
        </div>
        <Container EspacoSelecionado={EspacoSelecionado} DadosAgendamentos={DadosAgendamentos}>
          <div className="headerPag">
            <BackIcon
              EspacoSelecionado={EspacoSelecionado}
              onClick={() => {
                setEspacoSelecionado(0);
              }}
            >
            </BackIcon>
            <h1>Minhas Reservas</h1>
          </div>

          <div className="quadroDeEspacos">
            {MinhasReservas.map((e) => {
              if (EspacoSelecionado === 0) {
                return (
                  <div
                    className="listaDeEspacos"
                    id={e.id_local}
                    onClick={(() => {
                      setEspacoSelecionado(e.id_local)
                      setNomeEspacoSelecionado(e.descricao)
                    })}
                  >
                    <h2>{e.descricao}</h2>
                    <MoreInformation />
                  </div>)
              } else if (EspacoSelecionado === e.id_local) {
                return (
                  <div
                    className="espacoSelecionado"
                    id={e.id_local}
                  >
                    <h2>{e.descricao}</h2>
                    <LessInformation
                      onClick={(() => {
                        setEspacoSelecionado(0);
                        setNomeEspacoSelecionado("");
                      })}
                    />
                  </div>)
              }

            })}
          </div>
          <div className="moreInformation" style={{ display: EspacoSelecionado === 0 ? "none" : "flex", flexDirection:"column", alignItems:"center"}}>
            <ScrollTable EspacoSelecionado={EspacoSelecionado} DadosAgendamentos={DadosAgendamentos}>
              <Table>
                <thead>
                  <tr>
                    <th>Nome do Espaço</th>
                    <th>Descrição do Agendamento</th>
                    <th>Reservado Para</th>
                    <th>Hora Inicial</th>
                    <th>Hora Final</th>
                    <th>Agendado Em</th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {DadosAgendamentos.map((e, i) => {
                    return (
                      <tr key={i}>
                        <td>{NomeEspacoSelecionado}</td>
                        <td>{e.titulo}</td>
                        <td>{format({ DataInicial: e.dia, Formato: "DD/MM/YYYY" })}</td>
                        <td>{String(e.hora_inicial).slice(0, 5)}</td>
                        <td>{String(e.hora_final).slice(0, 5)}</td>
                        <td>{format({ DataInicial: e.agendadoEm, Formato: "DD/MM/YYYY" })}</td>
                        <td>
                          <ShowIcon
                            onClick={() => {
                              setShowModalExibirAgendamento(!ShowModalExibirAgendamento);
                              setAgendamentoSelecionado(i);
                            }}
                          />
                        </td>
                        <td>
                          <EditIcon
                            onClick={() => {
                              setShowEditarAgendamento(!ShowEditarAgendamento);
                              setAgendamentoSelecionado(i);
                              setPath("MyReserve")
                            }}
                          />
                        </td>
                        <td>
                          <RemoveIcon
                            onClick={() => {
                              deletaAgendamento(e.titulo, e.id_local, e.dia)
                            }}
                          />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </ScrollTable>
            
          </div>
          <div style={{display: EspacoSelecionado === 0 ? "none" : "flex"}}>
            <Pagination
                Page={Page}
                setPage={setPage}
                Limit={Limit}
                Count={DadosAgendamentos[0]?.count}
              />
          </div>  
        </Container>
      </Background>

      <ModalExibirAgendamento
        Show={ShowModalExibirAgendamento}
        setShow={setShowModalExibirAgendamento}
        Dados={DadosAgendamentos[AgendamentoSelecionado]}
      />
      <EditarAgendamento
        Show={ShowEditarAgendamento}
        setShow={setShowEditarAgendamento}
        Dados={DadosAgendamentos[AgendamentoSelecionado]}
        Reload={Reload}
        setReload={setReload}
        Path={Path}
      />
    </>
  )
}

export default MyReserve;