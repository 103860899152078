import styled from "styled-components";
import { IoIosAddCircleOutline } from "react-icons/io";

export const Container = styled.div`
  height: fit-content;
  align-items: center;
  min-width: 170px;
  width: fit-content;
  box-shadow: 0px 0px 2px 0px var(--secondary-font-color);
  border: 1px solid black;
  background-color: white;
  padding: 10px 0px;
  border-radius: 5px;
  text-align: left;
  margin: 2px 0;
  transition: height 0.5s;
  display: ${(props) => (props.disabled ? "none" : "")};

  .nd {
  }
  p {
    margin: 3px 6px;
    font-size: 15px;
  }

  .adicionar {
    text-align: center;
  }

  &:hover {
    cursor: pointer;
    border: 3px solid var(--first-auxiliary-color);
  }
`;

export const Add = styled(IoIosAddCircleOutline)`
  font-size: 35px;
`;
