import {
  Calendar,
} from "./style";
import InputLabel from "../../../InputLabel/index";
import { format } from "../../../../services/DateFormatter";


export default function TipoDiario({ repetirACada, setRepetirACada, dataReserva,  exibirTexto, prazoMax, setDataEncerramento, DataEncerramento}) {

  let textoASerExibido = repetirACada === 1 ? 
    `Ocorre todos os dias a partir de ${format({ DataInicial: dataReserva, Formato: "DD/MM/YYYY" })} até ${format({ DataInicial: DataEncerramento, Formato: "DD/MM/YYYY" }) || "?"}` 
    : 
    `Ocorre a cada ${repetirACada} dias a partir de ${format({ DataInicial: dataReserva, Formato: "DD/MM/YYYY" })} até ${format({ DataInicial: DataEncerramento, Formato: "DD/MM/YYYY" }) || "?"}`;

  return (
    <>
      <div style={{display:"flex", flexDirection:"row", width:"50%", marginLeft:"4%", marginBottom:"3%"}}>
        <InputLabel
          type="number"
          label="Repetir a cada"
          onChange={(e) => setRepetirACada(e.target.value)}
          value={repetirACada}
          defaultValue={1}
        />
        <span style={{ alignContent: "center", marginLeft: "4%", color: "var(--second-auxiliary-color)" }}>Dia(s)</span>
      </div>
      
      <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "flex-start", gap: "5%" }}>
          <div style={{ display: "flex", flexDirection: "row"}}>
            <Calendar />
            <InputLabel
              value={format({ DataInicial: dataReserva, Formato: "YYYY-MM-DD" })}
              type="date"
              label="Iniciar"
            />
        </div>

        <div style={{ width: "30%" }}>
          <InputLabel
            value={format({ DataInicial: DataEncerramento, Formato: "YYYY-MM-DD" }) || DataEncerramento}
            type="date"
            label="Encerrar"
            onChange={(e) => setDataEncerramento(e.target.value)}
            max={prazoMax}
          />
        </div>
      </div>

      <div style={{ display: exibirTexto ? "flex" : "none", flexDirection: "row", justifyContent: "flex-start", width: "100%", marginLeft: "10%", marginTop:"2%" }}>
        <p style={{ fontSize: "14px", color: "var(--second-auxiliary-color)" }}>{textoASerExibido}</p>
      </div>

      </>
  );
}
