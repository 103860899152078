import React, { useState, useEffect } from "react";
import { Table, PreviousOne, NextOne, Calendario } from "./style";
import Texts from "../../../assets/json/texts.json";

const DAYS_OF_WEEK = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];

export default function CalendarMonth({
  setTipoDeCalendario,
  setDataReserva,
  setDia,
  setMes,
  setAno,
  setDiaDaSemana,
}) {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [today] = useState(new Date());

  useEffect(() => {
    setCurrentDate(new Date());
  }, []);

  const getDaysInMonth = (year, month) =>
    new Date(year, month + 1, 0).getDate();

  const changeMonth = (delta) => {
    setCurrentDate(
      new Date(currentDate.setMonth(currentDate.getMonth() + delta))
    );
  };

  const isToday = (date) => {
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const renderCalendar = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDay = new Date(year, month, 1).getDay();
    const daysInMonth = getDaysInMonth(year, month);
    const daysInPrevMonth = getDaysInMonth(year, month - 1);
    const days = [];

    // Dias do mês anterior
    for (let i = firstDay - 1; i >= 0; i--) {
      const dayNumber = daysInPrevMonth - i;
      days.push(
        <p key={`prev-${i}`} style={{ backgroundColor: "gray" }}>
          {dayNumber}
        </p>
      );
    }

    // Dias do mês atual
    for (let i = 1; i <= daysInMonth; i++) {
      const date = new Date(year, month, i);
      days.push(
        <p
          key={`current-${i}`}
          onClick={() => {
            setTipoDeCalendario("diario");
            setDataReserva(date);
            setDia(i);
            setMes(month + 1);
            setAno(year);
            setDiaDaSemana(date.getDay());
          }}
          className={isToday(date) ? "styleHoje" : ""}
        >
          {i}
        </p>
      );
    }

    // Dias do próximo mês
    const totalDays = days.length;
    for (let i = 1; totalDays + i <= 42; i++) {
      days.push(
        <p key={`next-${i}`} style={{ backgroundColor: "gray" }}>
          {i}
        </p>
      );
    }

    return days;
  };

  return (
    <main>
      <Table>
        <caption
          style={{
            alignContent: "center",
            justifyContent: "center",
            height: "50px",
            margin: "2px",
            fontSize: "25px",
            alignItems: "center",
          }}
        >
          <PreviousOne onClick={() => changeMonth(-1)} />
          {Texts?.STATIC_MESES[currentDate.getMonth()]} /{" "}
          {currentDate.getFullYear()}
          <NextOne onClick={() => changeMonth(1)} />
        </caption>
        <thead>
          <tr>
            {DAYS_OF_WEEK.map((day) => (
              <th key={day}>{day}</th>
            ))}
          </tr>
        </thead>
      </Table>
      <Calendario>
        <section className="calendarioMensal">{renderCalendar()}</section>
      </Calendario>
    </main>
  );
}
